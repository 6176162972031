import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import SmallMenuItem from "./SmallMenuItem";
import { Divider } from "primereact/divider";
import { Translate } from "react-auto-translate";
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram, GrTwitter } from "react-icons/gr";

export default function SmallMenu({ visible, onHide, menu }) {
  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      showCloseIcon={false}
      className="w-full overflow-hidden m-0"
      blockScroll
    >
      <div
        className="flex flex-column relative overflow-x-hidden overflow-y-scroll p-3"
        style={{ height: "100vh" }}
      >
        <div className="flex justify-content-end">
          <Button
            icon="pi pi-times font-bold text-xl text-color"
            className="m-0 p-button-text"
            onClick={onHide}
          />
        </div>

        <div className="flex flex-column gap-4 px-4">
          {menu.map((i, _) => (
            <SmallMenuItem item={i} key={_} />
          ))}
        </div>
        <Divider className="my-4" />
        <div className="px-4 flex flex-column gap-4 mb-4">
          <div
            className="text-color text-lg cursor-pointer select-none hover:text-primary-300"
            onClick={(e) => window.open("https://social.justis.africa/")}
          >
            <Translate>Log in</Translate>
          </div>

          <div
            className="text-color text-lg cursor-pointer select-none hover:text-primary-300"
            onClick={(e) =>
              window.open("https://social.justis.africa/register")
            }
          >
            <Translate>Sign up</Translate>
          </div>
        </div>
        <div className="flex h-full flex-column justify-content-end px-4 pb-3">
          <div className="flex flex-column">
            <p>
              <strong>
                <Translate>Contact</Translate>
              </strong>
            </p>
            <small>info@justis.africa</small>
            <small>+233-302-213850. Ext.6318</small>
          </div>

          <div className="mt-2 flex flex-column">
            <p>
              <strong>
                <Translate>Social Media</Translate>
              </strong>
            </p>

            <div className="flex gap-4 align-items-center">
              <Button
                className="p-0 m-0 p-button-text"
                onClick={() =>
                  window.open("https://www.instagram.com/unuinra/")
                }
              >
                <GrInstagram size={20} />
              </Button>
              <Button
                className="p-0 m-0 p-button-text"
                onClick={() => window.open("https://twitter.com/UNUINRA")}
              >
                <GrTwitter size={20} />
              </Button>
              <Button
                className="p-0 m-0 p-button-text"
                onClick={() =>
                  window.open("https://web.facebook.com/UNUINRA/?_rdc=1&_rdr")
                }
              >
                <FaFacebookF size={20} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
