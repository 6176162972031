import React from "react";
import { Button } from "primereact/button";
import { ContextMenu } from "primereact/contextmenu";

import { CustomChart } from "./Charts/CustomChart";
import { CustomTable, CustomMultiTable } from "./Charts/CustomTable";
import { Divider } from "primereact/divider";

import Chart from "../../components/icons/Chart";
import Table from "../../components/icons/Table";

import { Translate } from "react-auto-translate";

export default function AnalyticsDetails({ title, data = [], options }) {
  const [type, setType] = React.useState(1);
  const [filter, setFilter] = React.useState(data.options?.filter);
  const [filteredData, setFilteredData] = React.useState();
  const [filterMenu, setFilterMenu] = React.useState([]);

  const cm = React.useRef();

  /*
   *
   */
  React.useEffect(() => {
    setFilter(data.options.filter);
    setFilteredData(
      data?.chartData?.filter((item) =>
        item.dataOn.includes(data.options?.filter)
      )[0]
    );
    setFilterMenu(
      data?.chartData?.map((i) => {
        return {
          label: <Translate>{i.dataOn}</Translate>,
          command: () => {
            setFilter(i.dataOn);
            setFilteredData(
              data?.chartData?.filter((item) =>
                item.dataOn.includes(i.dataOn)
              )[0]
            );
          },
        };
      })
    );
  }, [data]);

  return (
    <section
      className="flex flex-column justify-content-center align-items-center 
    gap-3 border-1 border-round border-gray-200 py-3 md:py-5 bg-white mt-4 mb-8 
    w-full   mx-auto"
    >
      <div className="flex flex-wrap gap-5 w-full justify-content-between align-items-center px-2  md:px-5">
        <div className="text-2xl md:text-4xl font-medium col-12 md:col pl-1 md:pl-0">
          <Translate>{title}</Translate>
        </div>
        <div className="flex flex-column align-items-center col-12 lg:col-5">
          <div className="flex align-items-center  justify-content-start lg:justify-content-end w-full gap-4">
            {data.options.showTable && (
              <Button
                className="p-button-sm p-button-text text-gray-400 py-0 px-1"
                onClick={() => setType(1)}
              >
                <Chart size={25} color="gray" />
              </Button>
            )}
            {data.options.showChart && (
              <Button
                className="p-button-sm p-button-text text-gray-400 py-0 px-1"
                onClick={() => setType(2)}
              >
                <Table size={25} color="gray" />
              </Button>
            )}
            {data.options.showFilter && (
              <Button
                className="p-button-sm p-button-outlined text-gray-500 py-2  flex align-items-center justify-content-between gap-3 text-sm"
                onClick={(e) => cm.current.show(e)}
              >
                <span className="white-space-nowrap text-left overflow-hidden text-overflow-ellipsis w-7rem font-medium">
                  <Translate>{`${filter}`}</Translate>{" "}
                </span>
                <i className="pi pi-chevron-down" />
              </Button>
            )}
          </div>
        </div>
      </div>

      <Divider />

      <>
        {data.options.tableOnly ? (
          <div className="w-full">
            <CustomMultiTable data={filteredData?.data ?? {}} />
          </div>
        ) : (
          <div className="w-full">
            {type === 1 ? (
              <CustomChart
                type={options.type}
                optionType={options.optionType}
                id={options.id}
                data={filteredData?.data ?? {}}
                className={options.className}
              />
            ) : (
              <CustomTable
                data={filteredData?.data ?? {}}
                percent={options.percent}
              />
            )}
          </div>
        )}
      </>

      {/* context Menu for dataOn select */}
      <ContextMenu
        model={filterMenu ? filterMenu : []}
        ref={cm}
        className="flex-auto w-12 md:w-5 bg-green-100"
      ></ContextMenu>

      {/* Description */}
      {/* <div className="w-full px-5 mt-5 flex-column align-items-center">
        <div className="text-xl md:text-3xl text-center font-semibold">
          Description
        </div>
        <div className="lg:mx-6 text-justify text-sm md:text-base font-light">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni quo
          fugit deleniti reprehenderit iure voluptatum quos libero rem
          inventore. Dolores voluptatem in asperiores ad amet, illo dolore
          maiores sint itaque! Lorem ipsum, dolor sit amet consectetur
          adipisicing elit. Nemo aspernatur minus, molestiae delectus quo
          architecto veritatis esse nobis tempora illo atque vitae numquam
          pariatur porro ab eligendi repellendus. Quasi, dolore. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Hic nobis dolorum,
          quibusdam repellendus dolore distinctio ratione earum! Quos,
          perspiciatis saepe ab minima ea quo unde sunt aliquid necessitatibus,
          ad quibusdam. Lorem, ipsum dolor sit amet consectetur adipisicing
          elit. Eligendi inventore rem laboriosam, alias, tenetur quidem
          possimus expedita pariatur corporis obcaecati eum, ea temporibus eius
          dolor corrupti? Ab in fuga nostrum?
        </div>
      </div> */}
    </section>
  );
}
