import React from "react";

// import sections
import Banner from "../components/home/VideoBanner";
import About from "../components/home/About";
import Social from "../components/home/Social";
import JustisNews from "../components/home/JustisNews";
import Analytics from "../components/home/Analytics";
import Footer from "../components/layouts/Footer";

export default function Home() {
  return (
    <main>
      <Banner />
      <About />
      <Analytics />
      <Social />
      <JustisNews />
      <Footer />
    </main>
  );
}
