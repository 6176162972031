import React from "react";
import { Image } from "primereact/image";

import doc from '../../assets/icons/document.svg'
import { Translate } from "react-auto-translate";

export default function Documents() {
  const proposals = [
    {
      name: "Research Proposal: Zambia",
      link:
        "https://docs.google.com/document/d/1jzVfxldAq4nYK-VkspoEXeNV4Dq_4kDL/edit?usp=sh" +
        "aring&ouid=108897230280221935194&rtpof=true&sd=true",
      type: "word",
      image: doc
    },
    {
      name: "Research Proposal: Namibia Case",
      link:
        "https://docs.google.com/document/d/1GsSA14VrkeSpDD8bBqydTAfxKKuJ3Zq0/edit?usp=sh" +
        "aring&ouid=108897230280221935194&rtpof=true&sd=true",
      type: "word",
      image: doc
    },
    {
      name: "Research Proposal: Ghana Case",
      link:
        "https://docs.google.com/document/d/1GsSA14VrkeSpDD8bBqydTAfxKKuJ3Zq0/edit?usp=sh" +
        "aring&ouid=108897230280221935194&rtpof=true&sd=true",
      type: "word",
      image: doc
    },
    {
      name: "Research Proposal Template For Country Case Studies",
      link:
        "https://docs.google.com/document/d/1AOSrnTTM1JsdOdZkYt8QRW3AaVrhEfjE/edit?usp=sh" +
        "aring&ouid=108897230280221935194&rtpof=true&sd=true",
      type: "word",
      image: doc
    },
  ];

  const concept = [
    {
      name:
        "Centering Informal Businesses For A Just, Inclusive And Sustainable Energy Trans" +
        "ition",
      link:
        "https://drive.google.com/file/d/1laf2gnOnvvvhCvEde2AD7sFt69I5pDca/view?usp=shari" +
        "ng",
      type: "pdf",
      image: doc
    },
    {
      name: "Just Transitions And The Informal Sector In Ghana",
      link:
        "https://docs.google.com/document/d/1mvr-uBZ7JMi1uQjsgufIHJsSSr3NK5hJ/edit?usp=sharing" ,
      type: "word",
      image: doc
    },
    {
      name: "Just Transitions And The Informal Sector (JUSTIS)",
      link: "https://docs.google.com/document/d/1laiMFxVK8R3NX4fyN8ESxS_2kMJB5ihU/edit?usp=sharing",
      type: "word",
      image: doc
    },
  ];

  const reports = [
    {
      name: "Country Summary Preliminary Findings Ghana",
      link: "https://docs.google.com/document/d/1S9NQURiDhkAKyXkOkdBGl08J2J5tMeI4wK7jJYRuoN4/edit?usp=sharing",
      type: "word",
      image: doc
    },
    {
      name: "Country Summary Preliminary Findings Namibia",
      link: "https://docs.google.com/document/d/1d1MtwLHasoGnUXGZfzbFMKKBVQB__q5M_DbLCr90N3Q/edit?usp=sharing",
      type: "word",
      image: doc
    },
    {
      name: "Country Summary Preliminary Findings Zambia",
      link: "https://docs.google.com/document/d/1DOWDSRGWJWDWx_RnubZH_lKT1-YzPd_hsGhhlzWk3Ko/edit?usp=sharing",
      type: "word",
      image: doc
    },
    {
      name: "Country Summary Preliminary Findings Côte D'Ivoire",
      link: "https://docs.google.com/document/d/18Fwfrodv6zgWeqjqU1ZmViKjQgbVcVTuiIoZT5wazUI/edit?usp=sharing",
      type: "word",
      image: doc
    },
    {
      name: "Specific Data on Countries Côte D'Ivoire",
      link: "https://docs.google.com/document/d/1iDei81CQozrJW55sPx8gRCl6VyjVFy7SF5gTxOf-bEc/edit?usp=sharing",
      type: "word",
      image: doc
    },
  ];

  const policy = [];

  const news = [];

  const peer = [];

  return (
    <section className="py-5 mx-3 lg:mx-8 align-items-center flex flex-column">
      {/* Reports */}
      <DocumentItem data={reports} title={"Research Findings"} />{" "}
      {/* Conceptual Framework */}
      <DocumentItem data={concept} title={"Conceptual Framework"} />{" "}
      {/*Proposals  */}
      <DocumentItem data={proposals} title={"Proposals"} />{" "}
      {/* Policy Briefs */}
      <DocumentItem data={policy} title={"Policy Briefs"} />{" "}
      {/*News And Articles */}
      <DocumentItem data={news} title={"News And Articles"} />{" "}
      {/*Peer Reviewed Articles*/}
      <DocumentItem data={peer} title={"Peer Reviewed Articles"} />
    </section>
  );
}

function DocumentItem({ data, title }) {
  return (
    <div className="">
      {data.length > 0 && (
        <React.Fragment>
          <div className="font-medium text-3xl md:text-4xl">
            <Translate>{title}</Translate>
          </div>
          <div className="grid py-5  align-items-center justify-content-start flex-wrap align-self-center gap-0">
            {data.map((d, index) => (
              <a
                key={index}
                href={d.link}
                target="_blank"
                rel="noreferrer"
                className="no-underline text-color col-12 lg:col-4"
              >
                <div className="bg-white col p-3  border-1 border-round border-gray-200 flex gap-3 align-items-center">
                  <Image src={d.image} alt={d.name} imageClassName="h-3rem" />

                  <p className="m-0 w-full">
                    <small>
                      <Translate>{d.name}</Translate>
                    </small>
                  </p>
                </div>
              </a>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
