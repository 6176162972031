import React from "react";
import { Translate } from "react-auto-translate";
import { Image } from "primereact/image";

import Logo from "../../assets/logo.svg";

export default function LogoStatement(params) {
  return (
    <div className="col-12 lg:col">
      <Image src={Logo} imageClassName="lg:h-5rem md:h-3rem h-2rem" />
      <div className="mt-4 lg:pr-5 text-sm md:text-base">
        <Translate>
          The JUSTIS digital portal provides a platform for entrepreneurs,
          policymakers and investors to connect in an online marketplace to
          retail green energy products and services.
        </Translate>
      </div>
      
    </div>
  );
}
