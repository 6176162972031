import React from "react";
import Navbar from "../layouts/Navbar";
import { useBreakpoints } from "react-breakpoints-hook";
import { Translate } from "react-auto-translate";

import { useThreshold } from "../../hooks/useThreshold";

export default function Banner() {
  let { xxs, xs, sm, md, lg } = useBreakpoints({
    xxs: {
      min: 0,
      max: 320,
    },
    xs: {
      min: 321,
      max: 560,
    },
    sm: {
      min: 561,
      max: 960,
    },
    md: {
      min: 961,
      max: 1300,
    },
    lg: {
      min: 1301,
      max: null,
    },
  });

  const threshold = useThreshold(460);

  return (
    <section>
      <div
        className={`
        about w-full relative
        mb-4 py-3 px-3 md:px-5 lg:px-8
      `}
        style={{
          height: xxs
            ? "75vh"
            : xs
            ? "75vh"
            : sm
            ? "75vh"
            : md
            ? "75vh"
            : "85vh",
        }}
      >
        <Navbar textColor={"text-white"} />

        <div className="h-full relative flex flex-column justify-content-end  relative pb-5">
          <div
            className={` 
            ${threshold && "mb-3"}
             font-bold text-white line-height-2 dontFuckingOverlap`}
            style={{
              width: xxs
                ? "calc(5ch + 50vw)"
                : xs
                ? "calc(5ch + 45vw)"
                : sm
                ? "calc(10ch + 30vw)"
                : md
                ? "calc(10ch + 25vw)"
                : lg
                ? "calc(10ch + 20vw)"
                : "",
              fontSize: xxs
                ? "calc(1rem + 1vh)"
                : xs
                ? "calc(1.1rem + 2.5vh)"
                : sm
                ? "calc(1.2rem + 3vh)"
                : md
                ? "calc(1.3rem + 4vh)"
                : lg
                ? "calc(1.3rem + 4vh)"
                : "",
            }}
          >
            <Translate>
              Let’s help you gain greater visibility and market access
            </Translate>
          </div>

          <div
            className={` 
          ${threshold && "hidden"}
          text-white mt-4 mb-5 md:mb-4 lg:mb-7 `}
            style={{
              width: xxs
                ? "calc(16ch + 50vw)"
                : xs
                ? "calc(15ch + 40vw)"
                : sm
                ? "calc(17ch + 40vw)"
                : md
                ? "calc(17ch + 40vw)"
                : lg
                ? "calc(10ch + 40vw)"
                : "",
              fontSize: xxs
                ? "calc(.2rem + 1vh)"
                : xs
                ? "calc(.2rem + 1vh)"
                : sm
                ? "calc(.3rem + 1vh)"
                : md
                ? "calc(.3rem + 1.3vh)"
                : lg
                ? "calc(.3rem + 1.4vh)"
                : "",
            }}
          >
            <Translate>
              The JUSTIS portal provides a platform for entrepreneurs,
              policymakers and investors to connect in an online marketplace to
              retail green energy products and services. It also provides data
              on informal sector workers and green entrepreneurs in Africa
            </Translate>
          </div>
        </div>
      </div>
    </section>
  );
}
