import { useState, useEffect } from "react";

export const  useThreshold = (threshold) => {
  const [belowThreshold, setBelowThreshold] = useState(false);

  useEffect(() => {
    function handleResize() {
      setBelowThreshold(window.innerHeight < threshold);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [threshold]);

  return belowThreshold;
}
