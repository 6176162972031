import React from "react";
import { Button } from "primereact/button";
import { Translate } from "react-auto-translate";
import SocialMedia from "./SocialMedia";

export default function SendMessage() {
  return (
    <div className="col-12 lg:col">
      <strong className="text-2xl  md:text-3xl">
        <Translate>Let’s facilitate the green transition together</Translate>
      </strong>

      <div className="mt-3">
        <a
          className="no-underline"
          href={`mailto:${"justisportal@gmail.com"}?subject=${
            encodeURIComponent("Message from Justis Portal") || ""
          }&body=${encodeURIComponent("Hi Admin,") || ""}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="border-noround">
            <span className="w-full flex align-items-center justify-content-center gap-3 ">
              <i className="pi pi-envelope" />
              <Translate>Send us a message</Translate>
            </span>
          </Button>
        </a>
      </div>

      <SocialMedia className="flex" />
    </div>
  );
}
