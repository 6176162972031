import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Image } from "primereact/image";

import Paginator from "../components/containers/Paginator";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";

import { Translate } from "react-auto-translate";
import { useContentfulGallery } from "../hooks/useContentfulGallery";
import { Badge } from "primereact/badge";

export default function Gallery() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const top = React.useRef(null);
  const [gallery, setGallery] = React.useState([]);
  const { getGallery } = useContentfulGallery();

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return gallery?.slice(startIndex, endIndex);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(gallery?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  React.useEffect(() => {
    getGallery().then((response) => setGallery(response[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="flex flex-column">
      <div className="flex flex-column w-full relative mb-4 py-3 px-3 md:px-5 lg:px-8">
        <Navbar />
      </div>

      <section className="px-3 md:px-5 lg:px-8 w-12 lg:w-11 flex flex-column mx-auto pb-5">
        <div className="font-medium text-xl md:text-3xl md:mt-5 lg:mt-8">
          <strong className="text-3xl md:text-5xl">
            <Translate>Gallery</Translate>
          </strong>
        </div>

        {/* tags */}
        <div className="flex gap-2 mt-2">
          {[
            ...new Map(gallery.map((item) => [item["tag"], item])).values(),
          ].map((i, _) => (
            <Badge
              key={_}
              value={i.tag.charAt(0).toUpperCase() + i.tag.slice(1)}
              size="large"
              className="bg-gray-200 text-sm border-round-2xl text-color font-light 
              hover:bg-primary-100 cursor-pointer"
            />
          ))}
        </div>

        <div ref={top} />
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 800: 2, 1300: 3 }}
        >
          <Masonry gutter={"10px"} className="my-5 lg:mt-8">
            {getCurrentItems()?.map((i, _) => (
              <div key={_}>
                <Image
                  src={i.url}
                  alt={i.title}
                  // imageClassName={`w-24rem`}
                  imageStyle={{
                    objectFit: "cover",
                    width: "100%",
                    display: "block",
                  }}
                />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>

        <Paginator
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          scrollTo={scrollTo}
          top={top}
          onBack={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
              scrollTo(top);
            }
          }}
          onNext={() => {
            if (currentPage < pageNumbers.length) {
              setCurrentPage(currentPage + 1);
              scrollTo(top);
            }
          }}
        />
      </section>

      <Footer />
    </main>
  );
}
