import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Translate } from "react-auto-translate";

export default function SmallMenuItem({ item }, key) {
  const activeClassName = "text-primary cursor-pointer";
  const inactiveClassName = `text-color transition-all no-underline cursor-pointer transition-duration-500  hover:underline hover:text-primary menu white-space-nowrap overflow-hidden text-overflow-clip  flex-auto`;
  const mediaInactiveClassName = `text-color transition-all no-underline cursor-pointer white-space-nowrap overflow-hidden text-overflow-clip select-none flex-auto`;
  const [showSubMenu, setShowSubMenu] = React.useState(false);
  const { pathname } = useLocation();

  const isMedia = () => {
    if (pathname.includes("media")) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div
        key={key}
        className="text-lg p-fluid w-12 flex flex-column"
      >
        {item.to === "media" ? (
          <div
            to={item.to}
            className={isMedia() ? activeClassName : mediaInactiveClassName}
            onClick={(e) => setShowSubMenu(!showSubMenu)}
          >
            <Translate>{item.name}</Translate>{" "}
            {item.hasSubmenu && (
              <i
                className={`${
                  showSubMenu ? "pi pi-chevron-up" : "pi pi-chevron-down"
                } text-xs`}
              />
            )}
            {showSubMenu && (
              <div className="flex flex-column gap-2 mt-3 ml-3">
                {item.hasSubmenu &&
                  item.subMenu.map((i, _) => (
                    <NavLink
                      to={i.to}
                      className={({ isActive }) =>
                        isActive ? activeClassName : inactiveClassName
                      }
                    >
                      <Translate>{i.name}</Translate>{" "}
                    </NavLink>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <NavLink
            to={item.to}
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <Translate>{item.name}</Translate>{" "}
            {item.hasSubmenu && <i className="pi pi-chevron-down text-xs" />}
          </NavLink>
        )}
      </div>
    </React.Fragment>
  );
}
