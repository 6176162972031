import React from "react";
import { Translate } from "react-auto-translate";
import circle from "../../assets/directory/circle.svg";
import { Image } from "primereact/image";

export default function Title() {
  return (
    <React.Fragment>
   
      <div className="text-4xl lg:text-6xl">
        <strong>
          <Translate>More than</Translate>{" "}
          <span style={{ color: "#F49D6E" }}>5000+</span>{" "}
          <Translate>entrepreneurs have been matched via the</Translate>{" "}
          <span className="circle">
            <span>JUSTIS</span>
          </span>{" "}
          <Translate>initiative.</Translate>
        </strong>
      </div>
    </React.Fragment>
  );
}
