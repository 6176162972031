import React from "react";

import VideoContent from "./VideoContent";
import video from "../../assets/home/bgVideo.mp4";
import poster from '../../assets/home/banner.webp'

export default function Banner({ largeText, largeTextExtra, extraText }) {
  return (
    <section
      className="flex flex-column relative"
      style={{
        height: "100vh",
      }}
    >
      <video
        autoPlay="autoplay"
        loop="loop"
        muted
        height="100%"
        className={`h-full w-12 absolute`}
        // videoclass="h-full w-12"
        style={{
          backgroundBlendMode: "darken",
          objectFit: "cover",
          margin: 0,
          padding: 0,
        }}
        poster={poster}
      >
        <source src={video} type="video/mp4" />
      </video>
      <main className="h-full w-full bg-black-alpha-10 z-1 py-3 px-3 md:px-5 lg:px-8  relative">
        <VideoContent />
      </main>
    </section>
  );
}
