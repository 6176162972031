import React from "react";
import {Image} from 'primereact/image'
import empty from '../../assets/directory/empty.svg'
import { Translate } from "react-auto-translate";

export default function Empty({ text }) {
  return (
    <div className=" flex flex-column p-5 justify-content-center align-items-center pointer-events-none">
      <Image src={empty} className="mb-5" />
      <span className="text-center">
        <Translate>{text}</Translate>
      </span>
    </div>
  );
}
