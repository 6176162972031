import React from "react";
import { Translate } from "react-auto-translate";
// firebase
import { onSnapshot, updateDoc, setDoc, doc } from "@firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { Image } from "primereact/image";
import { getFeaturedNews } from "../../utils/NewsUtils";
import empty from "../../assets/news/empty.svg";
import News from "../home/News";

export default function StoriesSegment() {
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function isInToday(inputDate) {
    var today = new Date();
    if (today.setHours(0, 0, 0, 0) === inputDate.setHours(0, 0, 0, 0)) {
      return true;
    } else {
      return false;
    }
  }

  /*
   * //!SUBSCRIBE TO NEWS API
   * Checks if news is not in localstorage and get news from google news if and only if back up
   * in firebase's date is not in today. it sets the new to local storage once it fetches from firebase the first time
   */

  const getNewsItems = () => {
    const storedNews = JSON.parse(localStorage.getItem("news"));
    const storedTime = JSON.parse(localStorage.getItem("time"));

    // CHECK TO SEE IF TIME IN TODAY, IF ITS TODAY FETCH FROM LOCAL STORAGE
    if (storedNews != null && isInToday(new Date(storedTime?.seconds * 1000))) {
      setNews(storedNews);
      return;
    } else {
      setLoading(true);
      onSnapshot(doc(db, "news", "9Z3kKtktK2dnZsoeU1Az"), (d) => {
        if (d.exists) {
          if (isInToday(new Date(d.data()?.time?.seconds * 1000))) {
            setNews(d.data().news);
            localStorage.setItem("news", JSON.stringify(d.data().news));
            localStorage.setItem("time", JSON.stringify(d.data().time));
          } else {
            console.log("news api");
            getFeaturedNews().then((items) => {
              setNews(items);
              updateDoc(doc(db, "news", "9Z3kKtktK2dnZsoeU1Az"), {
                news: items,
                time: new Date(),
              });
            });
          }
        } else {
          getFeaturedNews().then((items) => {
            setNews(items);
            setDoc(doc(db, "news", "9Z3kKtktK2dnZsoeU1Az"), {
              news: items,
              time: new Date(),
            });
          });
        }
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    let mounted = true;

    if (mounted) {
      getNewsItems();
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (news.length === 0)
    return (
      <div className="my-5 flex flex-column w-full align-items-center">
        <Image src={empty} className="my-5" />
        <div>
          <Translate>We currently have no records for your search</Translate>
        </div>
      </div>
    );

  return (
    <div className="my-3 md:my-7">
      <News data={news} />
    </div>
  );
}
