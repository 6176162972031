import { createClient } from "contentful";

export const useContentfulEvents = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY,
    host: process.env.REACT_APP_CONTENTFUL_PUBLISHED_HOST,
  });

  const getEvents = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "event",
        select: "fields",

      });

      const sanitizedEntries = entries.items.map((item) => {
        const events = item.fields;

        return {
          ...events,
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching news: ${error.message}`);
    }
  };

  return { getEvents };
};
