import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import React from "react";
import { Translate } from "react-auto-translate";
import Paginator from "../containers/Paginator";
import empty from "../../assets/news/empty.svg";

export default function Upcoming({ data }) {
  const filteredData = React.useMemo(
    () => data.filter((item) => new Date(item.eventDate) > new Date()),
    [data]
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(6);

  const top = React.useRef(null);

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }



  if (filteredData.length === 0)
    return (
      <div className="my-5 flex flex-column w-full align-items-center">
        <Image src={empty} className="my-5" />
        <div>
          <Translate>We currently have no records for you</Translate>
        </div>
      </div>
    );


  return (
    <section className="pt-5 lg:pt-6">
      <div ref={top} />
      {getCurrentItems()?.map((i, _) => (
        <div key={_} className="flex align-items-center gap-3 md:gap-5 grid">
          <div className="col-12 md:col-4 relative">
            <Badge
              value={<Translate>{i.type}</Translate>}
              size="large"
              className="font-light text-sm border-round-2xl md:hidden absolute"
              style={{ right: 15, top: 15 }}
            />
            <Image
              src={i.image.fields.file.url}
              imageClassName="h-15rem md:h-25rem w-full bg-primary-100"
              imageStyle={{
                objectFit: "cover",
                width: "100",
                display: "block",
              }}
            />
          </div>
          <div className="col flex flex-column align-items-start gap-3">
            <Badge
              value={<Translate>{i.type}</Translate>}
              size="large"
              className="font-light text-sm border-round-2xl hidden md:flex"
            />

            <strong className="text:2xl md:text-3xl lg:text-4xl lg:pr-8">
              <Translate>{i.title}</Translate>
            </strong>

            <div className="flex gap-4">
              <div className="flex align-items-center gap-2">
                <i className="pi pi-clock text-gray-400" />
                <Translate>
                  {new Date(i.eventDate).toLocaleString("en-us", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </Translate>
              </div>
              <div className="flex align-items-center gap-2">
                <i className="pi pi-building text-gray-400" />
                <Translate>{i.location}</Translate>
              </div>
            </div>

            <div className="text-sm lg:text-base lg:pr-8">
              <Translate>{i.description}</Translate>
            </div>

            <div>
              <Button
                className="border-noround p-button-sm"
                label={<Translate>Register</Translate>}
                onClick={() => window.open(`${i.registrationLink}`)}
              />
            </div>
          </div>
        </div>
      ))}

      <Paginator
        pageNumbers={pageNumbers}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        scrollTo={scrollTo}
        top={top}
        onBack={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollTo(top);
          }
        }}
        onNext={() => {
          if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
            scrollTo(top);
          }
        }}
      />
    </section>
  );
}
