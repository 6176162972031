import React from "react";
import Connect from "../../components/icons/Connect";
import { Translate } from "react-auto-translate";
import Unlock from "../icons/Unlock";
import Access from "../icons/Access";

const pointer = [
  {
    icon: <Connect color="#F49D6E" />,
    title: "Engage and connect",
    text: "The platform creates an avenue to engage and connect green entrepreneurs across Africa with a support infrastructure, enabling businesses to thrive, grow, and effectively contribute to green transition efforts.",
  },
  {
    icon: <Unlock color="#46B704" />,
    title: "Unlock potential investment",
    text: "The portal allows green entrepreneurs to unlock potential sources of investment while collaborating with policymakers and civil society organisations to overcome bottlenecks in green business.",
  },
  {
    icon: <Access color="#00B7F2" />,
    title: "Engage and connect",
    text: "The digital portal serves as a marketplace to retail green products and services to potential consumers, financial markets, and investors. The portal will also provide policymakers with a learning opportunity to support evidence-based climate and green policy decisions to support green businesses better and democratise research findings to a broader audience.",
  },
];

export default function Pointers() {
  return (
    <div className="flex flex-wrap justify-content-between w-full mt-2 md:mt-5 lg:mt-8">
      {pointer.map((i, _) => (
        <div className="w-12 lg:w-4 p-2 flex flex-column gap-3 mb-5 lg:pr-5">
          {i.icon}
          <div className="text-xl md:text-2xl lg:text-3xl font-semibold">
            <Translate>{i.title}</Translate>
          </div>
          <div className="text-base line-height-3">
            <Translate>{i.text}</Translate>
          </div>
        </div>
      ))}
    </div>
  );
}
