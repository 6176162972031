export default function Access({ size = 39, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1803 10.2857V17.5401H22.7294V12.768L14.024 21.4734L9.67135 17.1207L2.02016 24.7606L1 23.7404L9.67135 15.0577L14.024 19.4104L21.6979 11.7365H16.9258V10.2857H24.1803Z"
        fill={color}
        stroke={color}
        stroke-width="0.5"
      />
      <path
        d="M8.42868 12.7812V3C8.42868 1.89543 9.32411 1 10.4287 1H32.4287C33.5333 1 34.4287 1.89543 34.4287 3V25C34.4287 26.1046 33.5332 27 32.4287 27H10.4287C9.32411 27 8.42868 26.1046 8.42868 25V23.3438"
        stroke={color}
        stroke-width="2"
      />
    </svg>
  );
}
