import React from "react";
import Item from "./Item";
import Paginator from "../containers/Paginator";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function Results({ data }) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);

  const top = React.useRef(null);

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="mt-5 mx-auto lg:w-10">
      <div ref={top} />
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 800: 2, 1300: 3 }}>
        <Masonry gutter={"2rem"}>
          {getCurrentItems().map((item, index) => (
            <React.Fragment key={index}>
              <Item item={item} index={index} />
            </React.Fragment>
          ))}
        </Masonry>
      </ResponsiveMasonry>

      {/* PAGINATION */}

      <Paginator
        pageNumbers={pageNumbers}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        scrollTo={scrollTo}
        top={top}
        onBack={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollTo(top);
          }
        }}
        onNext={() => {
          if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
            scrollTo(top);
          }
        }}
      />
    </section>
  );
}
