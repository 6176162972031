import React from "react";
import { Translate } from "react-auto-translate";

export const CustomTable = ({ data, percent }) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center h-full py-3">
      {Object.keys(data).map((key, index) => (
        <div
          className="flex w-11 md:w-6 lg:w-5  justify-content-center bg-gray-100 py-2 px-4 "
          key={index}
        >
          <div className="col">
            {" "}
            <Translate>{key}</Translate>
          </div>
          <div className="col">
            {data[key]}
            {percent && "%"}
          </div>
        </div>
      ))}
    </div>
  );
};

export const CustomMultiTable = ({ data, percent }) => {
  return (
    <div className="flex align-items-start h-full mx-4 md:mx-5 lg:mx-8 p-2">
      {Object?.keys(data)?.map((key, index) => (
        <div
          className="flex justify-content-center flex-column col"
          key={index}
        >
          <div className="col bg-gray-200">
            <Translate>{key}</Translate>
          </div>
          {Array.isArray(data[key]) && (
            <div className="col flex flex-column gap-3 bg-gray-100">
              {data[key].map((item, index) => (
                <div key={index}>
                  <Translate>{item}</Translate>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
