import axios from "axios";

const API_KEY = process.env.REACT_APP_GOOGLE_NEWS_API_KEY;

// // format today to yyyy-mm-dd
// const formatDate = (date) => {
//   const d = new Date(date);
//   const month = String(d.getMonth() + 1);
//   const day = String(d.getDate());
//   const year = String(d.getFullYear());

//   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
// };

// const today = formatDate(new Date());

export const getFeaturedNews = async () => {
  //  top-healines from climate category
  var url = `https://gnews.io/api/v4/search?q="energy transition"&token=${API_KEY}`;

  const response = await axios.get(url);

  return response.data.articles;
};
