import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Translate } from "react-auto-translate";
import { appCountries } from "../../constants/countries";



import Title from "./Title";
import SubTitle from "./SubTitle";
import { useSettingsStore } from "../../zustand/useSettings";

export default function Resources({
  selectedCountry,
  setSelectedCountry,
  sector,
  setSector,
  onSearch,
  loading,
  disabled = true,
  setFilter,
}) {
  const { lang } = useSettingsStore();

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const onSectorChange = (e) => {
    setSector(e.value);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>
          {option.flag} <Translate>{option.name}</Translate>
        </div>
      </div>
    );
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>
            {option.flag} <Translate>{option.name}</Translate>
          </div>
        </div>
      );
    }

    return (
      <span>
        <Translate>{props.placeholder}</Translate>
      </span>
    );
  };

  return (
    <div className="py-4">
      <div className="lg:px-8">
        <div className="grid gap-1 mt-5 flex flex-wrap">
          <div className="col-12 lg:col">
            <Title />
            <SubTitle />
          </div>
          <div className="col-12 lg:col  hidden lg:flex directory" />
        </div>

        <div className="mt-5 md:mt-8 ">
          <p className="mb-5 lg:w-5 ">
            <Translate>
              Search from our directory of green entrepreneurs and learn about
              what they do
            </Translate>
          </p>

          <div className="flex flex-wrap gap-3  lg:w-8">
            <span className="p-input-icon-left flex-auto  p-fluid">
              <i className="pi pi-search" />
              <InputText
                placeholder={
                  lang === "fr" ? "Rechercher par nom" : "Search by name"
                }
                disabled={disabled}
                onChange={(e) => setFilter(e.target.value)}
              />
            </span>

            {/* <Dropdown
              className="flex-auto lg:flex"
              disabled={true}
              // value={selectedCountry}
              options={appCountries}
              onChange={onCountryChange}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder="Select Industry"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            /> */}

            <Dropdown
              className="flex-auto"
              value={selectedCountry}
              options={appCountries}
              onChange={onCountryChange}
              optionLabel="name"
              filter
              filterBy="name"
              placeholder="Select Country"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            />

            <Button
              className="p-button-sm  flex-auto"
              label={<Translate>Search</Translate>}
              onClick={onSearch}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
