import React from "react";
import { Translate } from "react-auto-translate";

export default function MoreButton({ onClick , text}) {
  return (
    <div
      className="no-underline flex gap-2 align-items-center cursor-pointer"
      onClick={onClick}
    >
      <i
        className="pi pi-chevron-right p-1 border-circle bg-primary-500 text-white"
        style={{ fontSize: ".4rem" }}
      />
      <span className="m-0 font-semibold hover:text-primary-400 text-base">
        <Translate>{text}</Translate>
      </span>
    </div>
  );
}
