import { Image } from "primereact/image";
import React from "react";
import { Translate } from "react-auto-translate";


import unu from "../../assets/about/unu.png";
import eca from "../../assets/about/eca.png";
import idrc from "../../assets/about/idrc.png";
export default function Sponsors() {
  return (
    <div className="flex flex-row flex-wrap gap-5 justify-content-between align-items-start mt-2 md:mt-5 lg:mt-7 w-full">
      <div className="flex flex-column gap-2 lg:w-6">
        <strong className="text-2xl md:text-4xl lg:text-5xl">
          <Translate>Our Partners</Translate>
        </strong>
        <div className="text-base line-height-3">
          <Translate>
            This initiative is a product birthed through a partnership project
            between The United Nations University-Institute for Natural
            Resources in Africa (UNU-INRA) and the United Nations Economic
            Commission for Africa (UNECA) as part of a project called Just
            Transitions in the Informal Sector (JUSTIS).
          </Translate>
        </div>
        <div className="mt-5 flex gap-4  flex-wrap justify-content-between">
          <div className="border-1 border-round-xl border-gray-200 p-4 col">
            <Image src={unu} alt="unu" imageClassName="w-full" />
          </div>
          <div className="border-1 border-round-xl border-gray-200 p-4 col">
            <Image src={eca} alt="unu" imageClassName="w-full" />
          </div>
        </div>
      </div>
      <div className="flex flex-column gap-2  lg:w-4 ">
        <strong className="text-2xl md:text-4xl lg:text-5xl">
          <Translate>Supported By</Translate>
        </strong>
        <div className="text-base line-height-3">
          <Translate>
            We are also supported by the International Development Research
            Centre. The IDRC invests in knowledge, innovation, and solutions to
            improve the lives of people in the developing world
          </Translate>
        </div>
        <div className="mt-5 flex gap-4  flex-wrap justify-content-between">
          <div className="border-1 border-round-xl border-gray-200 p-4  lg:col-8">
            <Image src={idrc} alt="unu" imageClassName="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
