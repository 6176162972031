export default function Connect({ size = 40, color = "blue" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8462 28H10.7692C9.58462 28 8.61539 27.0308 8.61539 25.8462V21.5385H10.7692V25.8462H25.8462V10.7692H21.5385V8.61539H25.8462C27.0308 8.61539 28 9.58462 28 10.7692V25.8462C28 27.0308 27.0308 28 25.8462 28Z"
        fill={color}
      />
      <path
        d="M18.3077 15.0769C17.7692 15.0769 17.3385 15.1846 16.9077 15.4L12.6 11.0923C12.8154 10.6615 12.9231 10.2308 12.9231 9.69231C12.9231 7.86154 11.5231 6.46154 9.69232 6.46154C7.86155 6.46154 6.46155 7.86154 6.46155 9.69231C6.46155 11.5231 7.86155 12.9231 9.69232 12.9231C10.2308 12.9231 10.6615 12.8154 11.0923 12.6L15.4 16.9077C15.1846 17.3385 15.0769 17.7692 15.0769 18.3077C15.0769 20.1385 16.4769 21.5385 18.3077 21.5385C20.1385 21.5385 21.5385 20.1385 21.5385 18.3077C21.5385 16.4769 20.1385 15.0769 18.3077 15.0769ZM8.61539 9.69231C8.61539 9.04616 9.04616 8.61539 9.69232 8.61539C10.3385 8.61539 10.7692 9.04616 10.7692 9.69231C10.7692 10.3385 10.3385 10.7692 9.69232 10.7692C9.04616 10.7692 8.61539 10.3385 8.61539 9.69231ZM18.3077 19.3846C17.6615 19.3846 17.2308 18.9538 17.2308 18.3077C17.2308 17.6615 17.6615 17.2308 18.3077 17.2308C18.9539 17.2308 19.3846 17.6615 19.3846 18.3077C19.3846 18.9538 18.9539 19.3846 18.3077 19.3846Z"
        fill={color}
      />
      <path
        d="M2.15385 0H17.2308C18.4154 0 19.3846 0.969231 19.3846 2.15385V6.46154H17.2308V2.15385H2.15385V17.2308H6.46154V19.3846H2.15385C0.969231 19.3846 0 18.4154 0 17.2308V2.15385C0 0.969231 0.969231 0 2.15385 0Z"
        fill={color}
      />
    </svg>
  );
}
