import React from "react";
import Lottie from "react-lottie";
import * as image from "../../lottie/error.json";

export default function Loading() {
  return (
    <div className=" flex flex-column p-8 justify-content-center align-items-center pointer-events-none">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: image,
        }}
        height={150}
        width={150}
      />
      <span className="font-medium text-xl">
        Opps! something went wrong, Try again
      </span>
    </div>
  );
}
