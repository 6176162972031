import React from "react";
import { Image } from "primereact/image";
import defaultImage from "../../assets/news/news.jpeg";
import { Translate } from "react-auto-translate";
import MoreButton from "../buttons/MoreButton";
import { useNavigate } from "react-router-dom";
import Wrapper from "../containers/Wrapper";
import { Badge } from "primereact/badge";
import ReactGA from "react-ga";
import { useContentfulNews } from "../../hooks/useContentfulNews";

export default function News() {
  const [news, setNews] = React.useState();
  const { getNews } = useContentfulNews();

  React.useEffect(() => {
    getNews().then((response) => setNews(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  console.log(news);

  return (
    <Wrapper className="flex flex-column w-11 gap-5 mx-auto">
      <div className="flex flex-wrap gap-2 justify-content-between align-items-center w-12 mb-2">
        <strong className="text-2xl md:text-4xl">
          <Translate>News and Events</Translate>
        </strong>

        <MoreButton text="See More" onClick={() => navigate("/media/news")} />
      </div>

      <div className="scroller gap-3 w-full">
        {news?.map((item, index) => {
          return (
            <a
              href={item.url}
              key={index}
              target="_blank"
              rel="noreferrer"
              className="text-color no-underline flex-auto flex-shrink-0"
              onClick={() =>
                ReactGA.event({
                  category: "News",
                  action: "Clicked on news item",
                  label: item.title,
                })
              }
            >
              <NewsItem key={index} item={item} />
            </a>
          );
        })}
      </div>
    </Wrapper>
  );
}

// News items
const NewsItem = ({ item }) => {
  return (
    <div className="w-full">
      <div className="h-15rem w-15rem md:h-20rem md:w-20rem lg:h-27rem lg:w-27rem bg-green-100 border-1 border-gray-200">
        <Image
          src={item?.imageUrl ?? defaultImage}
          alt={item?.id}
          imageClassName="h-full w-full"
          imageStyle={{
            objectFit: "cover",
          }}
        />
      </div>

      <div className=" w-15rem md:w-20rem lg:w-27rem">
        <div className="flex gap-2 md:gap-3 align-items-center  mt-3">
          <Badge
            value={<Translate>{item.type}</Translate>}
            className="border-round-2xl text-xs md:text-sm bg-primary-300 text-color px-3 md:hidden"
            size="small"
          />
          <Badge
            value={<Translate>{item?.type ?? "News"}</Translate>}
            className="border-round-2xl text-xs md:text-sm bg-primary-300 text-color px-3 hidden md:flex"
            size="large"
          />
          <div className="text-xs md:text-base font-light">
            <Translate>
              {new Date(item?.date).toLocaleDateString({
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </Translate>
          </div>
        </div>
        <p className="text-sm md:text-xl mb-0 font-semibold">
          <Translate>{item?.title}</Translate>
        </p>
      </div>
    </div>
  );
};
