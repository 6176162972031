import React from "react";
import { Chip } from "primereact/chip";
import { Dropdown } from "primereact/dropdown";
import { Translate } from "react-auto-translate";


export default function ChangeDistribution({
  analyticsData,
  setSelected,
  setDistribution,
  setOptions,
  selected,
}) {
  const [show, setShow] = React.useState(false);

  const activeDist =
    "cursor-pointer text-white text-xs bg-primary hover:bg-primary-400  px-4 border-round-sm";
  const inactiveDist =
    "cursor-pointer text-gray-500 text-xs hover:bg-primary-100 px-4 border-round-sm";

  const [search, setSearch] = React.useState();
  const [option, setOption] = React.useState();

  React.useEffect(() => {
    setOption(
      analyticsData.map((item) => {
        return {
          name: item.distribution,
        };
      })
    );
    setSearch({ name: "Sector By Activity" });
  }, []);

  return (
    <div>
      <div className="mx-auto flex flex-column justify-content-center align-items-center">
        <Dropdown
          tooltip="Please select a distribution"
          tooltipOptions={{
            position: "top",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          value={search}
          options={option}
          onChange={(e) => {
            setSearch(e.target.value);
            setSelected(e.target.value.name);
            setDistribution(
              analyticsData.filter(
                (item) => item.distribution === e.target.value.name
              )[0]
            );
            setOptions(
              analyticsData.filter(
                (item) => item.distribution === e.target.value.name
              )[0].options
            );
            // setShow(false);
          }}
          optionLabel="name"
          filter
          filterBy="name"
          placeholder="Please select distribution"
          className="w-12 lg:w-8 p-textinput-sm"
          autoFocus={false}
          scrollHeight="60vh"
          valueTemplate={(option, props) => {
            if (option) {
              return (
                <div className="flex align-items-center">
                  <Translate>{option.name}</Translate>
                </div>
              );
            }

            return <Translate>{props.placeholder}</Translate>;
          }}
          itemTemplate={(option) => {
            return (
              <div className="flex align-items-center">
                <Translate>{option?.name}</Translate>
              </div>
            );
          }}
        />

        {/* <div className="hidden md:flex gap-2 justify-content-center flex-wrap mt-3  w-11 md:w-10  mx-auto">
          {analyticsData.map((dist, index) => {
            return (
              <Chip
                key={index}
                label={dist.distribution}
                onClick={() => {
                  setSelected(dist.distribution);
                  setDistribution(dist);
                  setOptions(dist.options);
                  setShow(false);
                  setSearch({ name: dist.distribution });
                }}
                className={`${
                  selected === dist.distribution ? activeDist : inactiveDist
                }`}
              />
            );
          })}
        </div> */}

        {/* <Button
          label="SELECT ANALYTICS AREA"
          // icon="pi pi-filter"
          // iconPos="right"
          className="p-button-outlined"
          onClick={() => setShow(!show)}
        /> */}
      </div>
      {/* <Dialog
        visible={show}
        onHide={() => setShow(!show)}
        showHeader={true}
        className="w-10 md:w-9 lg:w-8"
        footer={() => <div />}
        blockScroll
      >
        <div
          className="flex justify-content-center flex-wrap 
        gap-4 p-5"
        >
          {analyticsData.map((dist, index) => {
            return (
              <Chip
                key={index}
                label={dist.distribution}
                onClick={() => {
                  setSelected(dist.distribution);
                  setDistribution(dist);
                  setOptions(dist.options);
                  setShow(false);
                }}
                className={`${
                  selected === dist.distribution ? activeDist : inactiveDist
                }`}
              />
            );
          })}
        </div>
      </Dialog> */}
    </div>
  );
}
