import { Image } from "primereact/image";
import { Divider } from "primereact/divider";
import React from "react";
import { Translate } from "react-auto-translate";
import { useNavigate } from "react-router-dom";
import MoreButton from "../buttons/MoreButton";
import { useBreakpoints } from "react-breakpoints-hook";
import bars from "../../assets/home/bars.png";
import lines from "../../assets/home/lines.png";
import circles from "../../assets/home/circles.png";

export default function Analytics() {
  const navigate = useNavigate();

  let { xs, sm, md, lg } = useBreakpoints({
    xs: {
      min: 0,
      max: 560,
    },
    sm: {
      min: 561,
      max: 960,
    },
    md: {
      min: 961,
      max: 1300,
    },
    lg: {
      min: 1301,
      max: null,
    },
  });

  return (
    <section className="flex justify-content-center align-items-center my-4 md:my-6 py-6 px-0 md:px-5">
      <div className="flex flex-column w-11 gap-2">
        <div className="flex flex-wrap gap-2 justify-content-between align-items-end w-12">
          <strong className="text-2xl md:text-4xl" style={{ width: "22ch" }}>
            <Translate>Browse our data catalogue by sector</Translate>
          </strong>

          <MoreButton
            text="Explore Data"
            onClick={() => navigate("/resources")}
          />
        </div>
        <Divider />
        <div className="flex justify-content-between gap-5 flex-wrap my-6">
          {/* bars  */}
          <div className="w-12 lg:w-auto flex-auto flex justify-content-center lg:w-auto">
            <div>
              <Image
                alt="bar chart"
                src={bars}
                imageClassName="w-full lg:w-15rem"
              />

              <div className="my-5">
                <strong className="text-5xl md:text-6xl lg:text-7xl">
                  120K
                </strong>
                {/* total views */}
                <div className="flex gap-4">
                  <div
                    className="text-gray-400 font-medium"
                    style={{ fontSize: 12 }}
                  >
                    <Translate>Total Views</Translate>
                  </div>
                  <div
                    className="text-gray-400 font-medium"
                    style={{ fontSize: 12 }}
                  >
                    <Translate>Views</Translate>{" "}
                    <span className="text-primary-500">
                      {" "}
                      53% <i className="pi pi-chevron-up text-xs" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider layout="vertical" className="hidden lg:block m-0 p-0" />

          {/* lines */}
          <div className="w-12 lg:w-auto flex-auto flex justify-content-center">
            <div>
              <Image
                alt="bar chart"
                src={lines}
                imageClassName="w-full lg:w-15rem"
              />

              <div className="my-5 flex flex-column">
                <strong className="text-5xl md:text-6xl lg:text-7xl">
                  20.35
                </strong>
                {/* total views */}
                <div className="flex gap-4">
                  <div
                    className="text-gray-400 font-medium"
                    style={{ fontSize: 12 }}
                  >
                    <Translate>Total Views</Translate>
                  </div>
                  <div
                    className="text-gray-400 font-medium"
                    style={{ fontSize: 12 }}
                  >
                    <Translate>Views</Translate>{" "}
                    <span className="text-primary-500">
                      {" "}
                      53% <i className="pi pi-chevron-up text-xs" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider layout="vertical" className="hidden lg:block m-0 p-0" />

          {/* circles  */}
          <div className="w-12 lg:w-5 flex-auto flex justify-content-center  gap-2">
            <Image
              alt="bar chart"
              src={circles}
              imageClassName="flex-auto w-7rem  lg:w-11rem"
            />
            {/* text start */}
            {/* -------------------------- */}
            <div className="mt-1 md:mt-3">
              {/* heading */}
              <div className="flex flex-wrap gap-3 align-items-center mb-3">
                <div className="flex flex-column">
                  <div className="flex align-items-center gap-2">
                    <span
                      className=" bg-orange-300 border-circle"
                      style={{ padding: 6 }}
                    />
                    <strong className="text-sm md:text-base">$1,876</strong>
                  </div>
                  <em className="text-gray-400" style={{ fontSize: 10 }}>
                    <Translate>Monthly Total</Translate>
                  </em>
                </div>

                <div className="flex flex-column">
                  <div className="flex align-items-center gap-2">
                    <span
                      className=" bg-orange-100 border-circle"
                      style={{ padding: 6 }}
                    />
                    <strong className="text-sm md:text-base">$21,000</strong>
                  </div>
                  <em className="text-gray-400" style={{ fontSize: 10 }}>
                    <Translate>Yearly Total</Translate>
                  </em>
                </div>
              </div>
              <div
                className="text-xs md:text-sm"
                style={{
                  width: xs
                    ? "25ch"
                    : sm
                    ? "30ch"
                    : md
                    ? "30ch"
                    : lg
                    ? "30ch"
                    : "25ch",
                }}
              >
                <Translate>
                  The JUSTIS digital portal provides a platform for
                  entrepreneurs, policymakers and investors to connect in an
                  online marketplace to retail green energy products and
                  services.
                </Translate>
              </div>
            </div>
            {/* text end */}
            {/* -------------------------- */}
          </div>
        </div>
      </div>
    </section>
  );
}
