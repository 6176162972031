import React from "react";
import Footer from "../components/layouts/Footer";
import Navbar from "../components/layouts/Navbar";
import { Translate } from "react-auto-translate";
import { TabMenu } from "primereact/tabmenu";

import NewsSegment from "../components/news/NewsSegment";
import StoriesSegment from "../components/news/StoriesSegment";

export default function News() {
  const [tab, setTab] = React.useState(0);

  const items = [
    { label: <Translate>News</Translate> },
    { label: <Translate>Stories</Translate> },
  ];

  return (
    <main className="flex flex-column">
      <div className="flex flex-column w-full relative mb-4 py-3 px-3 md:px-5 lg:px-8">
        <Navbar />

        <section className="w-12 lg:w-11 flex flex-column mx-auto lg:mt-5">
          <div className="mt-5 md:mt-8 mb-1">
            <strong className="text-3xl md:text-5xl">
              <Translate>News & Stories</Translate>
            </strong>
          </div>

          <div>
            <p className="lg:w-5 text-sm md:text-base">
              <Translate>Latest news and success stories on Justis.</Translate>
              <br />
              <Translate>
                Stories are daily top stories relating to green enery and energy
                transitions
              </Translate>
            </p>
          </div>

          <TabMenu
            model={items}
            activeIndex={tab}
            onTabChange={(e) => setTab(e.index)}
            className="text-lg md:text-xl"
          />

          {tab === 0 ? <NewsSegment /> : <StoriesSegment />}
        </section>
      </div>

      <Footer />
    </main>
  );
}
