import React from "react";
import { Chart } from "primereact/chart";
import {
  verticalOptions,
  horizontalOptions,
  lineOptions,
  basicOptions,
  polarAreaOptions,
} from "./options";

import { Translate } from "react-auto-translate";

export const CustomChart = (props) => {
  const {
    data,
    type,
    optionType,
    id,
    className = " w-11 md:w-3 relative",
  } = props;

  const chartRef = React.useRef();

  // chart data
  const dataSet = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "",
        data: Object.values(data),
        backgroundColor:
          type === "pie"
            ? ["#A7CFAC", "#A5CDD9"]
            : [
                "#0c2e55",
                "#12417a",
                "#2d5f9d",
                "#3264a2",
                "#5787c7",
                "#6f90c5",
                "#7994c3",
                "#88a2d1",
                "#a8bbda",
                "#ced9eb",
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
                "#" + Math.floor(Math.random() * 19777215).toString(16),
              ],
      },
    ],
  };

  const options =
    optionType === "vertical"
      ? verticalOptions
      : optionType === "horizontal"
      ? horizontalOptions
      : optionType === "line"
      ? lineOptions
      : optionType === "polarArea"
      ? polarAreaOptions
      : basicOptions;

  return (
    <div className="w-full flex flex-column align-items-center justify-content-center">
      <Chart
        ref={chartRef}
        id={id}
        type={type}
        data={dataSet}
        options={options}
        className={className}
      />
    </div>
  );
};
