import React from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import Search from "../components/inventory/Search";
import Results from "../components/inventory/Results";
import Loading from "../components/inventory/Loading";
import Error from "../components/inventory/Error";
import Empty from "../components/inventory/Empty";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";


export default function Directory() {
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [sector, setSector] = React.useState(null);
  const [results, setResults] = React.useState([]);
  const [text, setText] = React.useState(
    "Select a filter and search for entrepreneurs"
  );
  const [filter, setFilter] = React.useState("");



  const [errorResult, setErrorResult] = React.useState({
    message: "",
    errorHappened: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const getItems = async () => {
    const q = query(
      collection(db, "users"),
      where("country.name", "==", selectedCountry?.name),
      where("userType", "==", "entrepreneur")
    );
    setIsLoading(true);
    await getDocs(q)
      .then((querySnapshot) => {
        querySnapshot?.docs?.length === 0 && setText("No entrepreneurs found");
        const newResults = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setResults(newResults);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setErrorResult({
          message: error.code,
          errorHappened: true,
        });
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    let mounted = true;

    if (mounted) {
      getItems();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <main className="">
      <div className="flex flex-column w-full relative mb-4 py-3 px-3 md:px-5 lg:px-8">
        <Navbar />
        <Search
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          sector={sector}
          setSector={setSector}
          onSearch={() => selectedCountry?.name && getItems()}
          loading={isLoading}
          disabled={results.length === 0 ? true : false}
          setFilter={setFilter}
        />

        {results.length === 0 ? (
          <Empty text={text} />
        ) : results.length === 0 && errorResult.errorHappened ? (
          <Error />
        ) : (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                <Results
                  data={results.filter((item) =>
                    item.name.toLowerCase().includes(filter.toLowerCase())
                  )}
                />
               
              </div>
            )}
          </>
        )}
      </div>

      <Footer />
    </main>
  );
}
