import { createClient } from "contentful";

export const useContentfulGallery = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY,
    host: process.env.REACT_APP_CONTENTFUL_PUBLISHED_HOST,
  });

  const getGallery = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "gallery",
        select: "fields",
        
      });

      const sanitizedEntries = entries.items.map((item, _) => {
        const gallery = item.fields.gallery;

        return gallery.map((i) => {
          return {
            tag: i.metadata?.tags[0]?.sys?.id,
            url: i.fields?.file?.url,
            title: i?.fields?.title,
          };
        });
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching news: ${error.message}`);
    }
  };

  return { getGallery };
};
