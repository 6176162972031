import React from "react";

export default function Table({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4737 0.5H3.52632C2.13684 0.5 1 1.65 1 3.05556V20.9444C1 22.35 2.13684 23.5 3.52632 23.5H22.4737C23.8632 23.5 25 22.35 25 20.9444V3.05556C25 1.65 23.8632 0.5 22.4737 0.5ZM22.4737 3.05556V6.88889H3.52632V3.05556H22.4737ZM16.1579 20.9444H9.8421V9.44444H16.1579V20.9444ZM3.52632 9.44444H7.31579V20.9444H3.52632V9.44444ZM18.6842 20.9444V9.44444H22.4737V20.9444H18.6842Z"
        fill={color}
        stroke={color}
        stroke-width="0.001"
      />
    </svg>
  );
}
