import React from "react";
import { Translate } from "react-auto-translate";
import Wrapper from "../../components/containers/Wrapper";
import Pointers from "./Pointers";
import JustisProject from './JustisProject'
import Sponsors from "./Sponsors";
import MeetTeam from "./MeetTeam";

export default function Content() {
  return (
    <Wrapper className="flex flex-column w-11 gap-5 align-items-center">
      <Pointers />
      <JustisProject/>
      <Sponsors/>
      <MeetTeam/>
    </Wrapper>
  );
}
