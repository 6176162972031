import React from "react";
import { Button } from "primereact/button";
import { Translate } from "react-auto-translate";
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram, GrTwitter } from "react-icons/gr";

export default function SocialMedia({ className }) {
  return (
    <div className={`mt-2 flex-column ${className}`}>
      <p>
        <strong className="text-base md:text-xl">
          <Translate>Social Media</Translate>
        </strong>
      </p>

      <div className="flex gap-4 align-items-center">
        <Button
          className="p-0 m-0 p-button-text"
          onClick={() => window.open("https://www.instagram.com/unuinra/")}
        >
          <GrInstagram size={24} />
        </Button>
        <Button
          className="p-0 m-0 p-button-text"
          onClick={() => window.open("https://twitter.com/UNUINRA")}
        >
          <GrTwitter size={24} />
        </Button>
        <Button
          className="p-0 m-0 p-button-text"
          onClick={() =>
            window.open("https://web.facebook.com/UNUINRA/?_rdc=1&_rdr")
          }
        >
          <FaFacebookF size={24} />
        </Button>
      </div>
    </div>
  );
}
