import React from "react";
import { Translate } from "react-auto-translate";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

export default function ContactLink() {
  return (
    <div className="col-12 lg:col-4 mt-4 md:mt-0">
      <div className="flex  gap-5">
        {/* CONTACTS */}
        <div className="w-6">
          <div>
            <strong className="text-lg md:text-xl ">
              <Translate>Contact</Translate>
            </strong>
          </div>

          <div className="my-5">
            <div className="flex flex-column gap-1 md:gap-2 text-xs md:text-base">
              <p className="m-0">
                <Translate>International House Legon</Translate>
              </p>
              <p className="m-0">
                <Translate>Annie Jiage Road</Translate>
              </p>
              <p className="m-0">
                <Translate>University of Ghana, Legon</Translate>
              </p>
              <p className="m-0">
                <Translate>Campus Accra, Ghana.</Translate>
              </p>
              <a
                href="mailto:apps.fred.adu@gmail.com"
                className=" no-underline text-color"
              >
                info@justis.africa
              </a>
              <a
                href="tel:+233-302-213850"
                className=" no-underline text-color"
              >
                +233-302-213850. Ext.6318
              </a>
            </div>
          </div>
        </div>
        {/* LINKS */}
        <div className="w-6">
          <div>
            <strong className="text-lg md:text-xl">
              <Translate>Quick Links</Translate>
            </strong>
          </div>

          <div className="my-5 flex flex-column gap-2 md:gap-3 text-sm md:text-base">
            <p className="m-0">
              <Link to="/" className="text-color no-underline">
                <Translate>Home</Translate>
              </Link>
            </p>
            <p className="m-0">
              <Link to="/about" className="text-color no-underline">
                <Translate>About Us</Translate>
              </Link>
            </p>
            <p className="m-0">
              <Link to="/resources" className="text-color no-underline">
                <Translate>Resources</Translate>
              </Link>
            </p>
            <p className="m-0">
              <Link to="/media/news" className="text-color no-underline">
                <Translate>Media</Translate>
              </Link>
            </p>

            <p className="m-0">
              <Link to="/directory" className="text-color no-underline">
                <Translate>Directory</Translate>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
