import React from "react";
import { Image } from "primereact/image";
import { Translate } from "react-auto-translate";

import unu from "../../assets/about/unu.png";
import eca from "../../assets/about/eca.png";
import idrc from "../../assets/about/idrc.png";

export default function Sponsors() {
  return (
    <div className="col flex flex-wrap align-items-start gap-4">
      <div>
        <strong className="text-base md:text-xl">
          <Translate>Partners</Translate>
        </strong>
        <div className="flex align-items-center gap-4 mt-4">
          <Image src={eca} alt="unu" imageClassName="h-3rem md:h-3rem" />
          <Image src={unu} alt="unu" imageClassName="h-3rem md:h-3rem" />
        </div>
      </div>
      <div>
        <strong className="text-base md:text-xl ">
          <Translate>Supported by</Translate>
        </strong>
        <div className="flex align-items-center gap-3 mt-4">
          <Image src={idrc} alt="unu" imageClassName="h-3rem md:h-3rem" />
        </div>
      </div>
    </div>
  );
}
