import React from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { Translate } from "react-auto-translate";

// assets
import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logo-white.svg";
import Menu from "./Menu";

import LanguageSelect from "./LanguageSelect";
import SmallMenu from "./SmallMenu";

export default function Navbar({ textColor }) {
  const menu = [
    {
      name: "Home",
      to: "/home",
      hasSubmenu: false,
    },
    {
      name: "About us",
      to: "/about",
      hasSubmenu: false,
    },
    {
      name: "Resources",
      to: "/resources",
      hasSubmenu: false,
    },
    {
      name: "Media",
      to: "media",
      hasSubmenu: true,
      subMenu: [
        { name: "News & Stories", to: "/media/news" },
        { name: "Events", to: "/media/events" },
        { name: "Gallery", to: "/media/gallery" },
      ],
    },
    {
      name: "Directory",
      to: "/directory",
      hasSubmenu: false,
    },
  ];

  const [visible, setVisible] = React.useState(false);

  return (
    <div className="z-5 flex gap-5 justify-content-between align-items-center">
      {/* LOGO */}
      <div className="">
        <NavLink to="/home">
          <Image
            src={textColor ? logoWhite : logo}
            imageClassName="h-2rem md:h-2rem mt-2"
            alt="logo"
          />
        </NavLink>
      </div>
      {/* MAIN MENU */}
      <div className="flex flex-1 ">
        {/* LARGE SCREEN */}
        <div className="w-full  justify-content-center align-items-center gap-3 hidden lg:flex">
          {menu.map((i, _) => (
            <Menu
              key={_}
              to={i.to}
              name={i.name}
              textColor={textColor}
              hasSubmenu={i.hasSubmenu}
              subMenu={i.subMenu}
            />
          ))}
        </div>
      </div>
      <div className="flex align-items-center gap-2">
        <LanguageSelect textColor={textColor}/>

        <Button
          className={`border-noround p-button-outlined ${textColor ? "text-white" : "text-color"} hidden lg:flex p-button-sm`}
          icon="pi pi-user"
          label={<Translate>Login</Translate>}
          onClick={() => window.open("https://social.justis.africa/")}
        />

        <Button
          className={`p-button-text ${
            textColor ? "text-white" : "text-color"
          } lg:hidden`}
          icon="pi pi-bars text-xl"
          onClick={() => setVisible(true)}
        />

        <SmallMenu
          visible={visible}
          onHide={() => setVisible(false)}
          menu={menu}
         
        />
      </div>
    </div>
  );
}
