import React from "react";
import Footer from "../components/layouts/Footer";
import Navbar from "../components/layouts/Navbar";
import { Translate } from "react-auto-translate";
import { TabMenu } from "primereact/tabmenu";

import Upcoming from "../components/events/Upcoming";
import Past from "../components/events/Past";
import { useContentfulEvents } from "../hooks/useContentfulEvents";



export default function Events() {
  const [tab, setTab] = React.useState(0);
  const [events, setEvents] = React.useState([]);

  const items = [
    { label: <Translate>Upcoming</Translate> },
    { label: <Translate>Past</Translate> },
  ];

  const { getEvents } = useContentfulEvents();

  React.useEffect(() => {
    getEvents().then((response) => setEvents(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="flex flex-column">
      <div className="flex flex-column w-full relative mb-4 py-3 px-3 md:px-5 lg:px-8">
        <Navbar />

        <section className="w-12 lg:w-11 flex flex-column mx-auto lg:mt-5">
          <div className="mt-5 md:mt-8 mb-5">
            <strong className="text-3xl md:text-5xl">
              <Translate>Events</Translate>
            </strong>
          </div>

          <TabMenu
            model={items}
            activeIndex={tab}
            onTabChange={(e) => setTab(e.index)}
            className="text-lg md:text-xl"
          />

          {tab === 0 ? <Upcoming data={events} /> : <Past data={events} />}
        </section>
      </div>

      <Footer />
    </main>
  );
}
