import React from "react";
import { Chip } from "primereact/chip";
import { Image } from "primereact/image";
import { Translate } from "react-auto-translate";

export default function Item({ index, item }) {
  return (
    <div
      key={index}
      className="border-1 border-gray-200 shadow-1 border-round 
          gap-2 cursor-pointer bg-white  hover:bg-primary-500
          hover:shadow-3 flex flex-column
          "
    >
      <div className="px-3 pt-3">
        <Image
          src={item.profileImage}
          imageClassName="h-5rem border-round"
          imageStyle={{
            objectFit: "contain",
          }}
        />
      </div>
      <div className="flex flex-column justify-content-end flex-auto">
        <div className="flex justify-content-between align-items-center w-full px-3">
          <div className="font-medium flex-auto">{item.name}</div>
        </div>

        {/* large screen */}
        <div className="mt-3 flex flex-wrap gap-3 px-3 mb-3">
          <Chip
            icon="pi pi-user"
            label={<Translate>Justis Profile</Translate>}
            className="cursor-pointer hover:bg-primary-100 text-xs md:text-sm"
            onClick={() =>
              window.open(
                "https://social.justis.africa/guest/profile/" + item.id,
                "_blank"
              )
            }
          />

          {item?.address && (
            <Chip
              icon="pi pi-map-marker"
              label={<Translate>Location</Translate>}
              className="cursor-pointer hover:bg-primary-100 text-xs md:text-sm"
              onClick={() => {
                window.open(`https://maps.google.com?q="${item.address}"`);
              }}
            />
          )}

          {item?.phone && item?.phone !== "N/A" && (
            <Chip
              icon="pi pi-phone"
              label={item.phone}
              className="cursor-pointer hover:bg-primary-100 text-xs md:text-sm"
              onClick={() => {
                window.open(`tel://${item.phone}`, "_self");
              }}
            />
          )}

          {item?.email && (
            <Chip
              icon="pi pi-envelope"
              label={<Translate>Email</Translate>}
              className="cursor-pointer hover:bg-primary-100 text-xs md:text-sm"
              onClick={() => {
                window.open(`mailto://${item.email}`);
              }}
            />
          )}

          {item?.website && (
            <Chip
              icon="pi pi-globe"
              label={<Translate>Website</Translate>}
              className="cursor-pointer hover:bg-primary-100 text-xs md:text-sm"
              onClick={() => {
                window.open(
                  item.website.match(/^https?:/)
                    ? item.website
                    : "//" + item.website
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
