import React from "react";
import { Translate } from "react-auto-translate";
import { Button } from "primereact/button";

export default function Paginator({
  pageNumbers,
  currentPage,
  handlePageChange,
  onBack,
  onNext,
  scrollTo,
  top
}) {
  return (
    <div className="flex align-items-center justify-content-center gap-2 mt-7">
      <Button
        className="p-0 text-xs p-button-text flex gap-2 "
        onClick={onBack}
      >
        <i className="pi pi-chevron-left text-xs" /> <Translate>Back</Translate>
      </Button>
      {pageNumbers.map((i, _) => (
        <span key={_} className="p-buttonset">
          <Button
            key={_}
            className={`p-button-sm border-round-sm px-2 py-0  ${
              i === currentPage ? "" : "p-button-outlined bg-primary-100"
            }`}
            onClick={() => {
              handlePageChange(i);
              scrollTo(top)
            }}
          >
            {i}
          </Button>
        </span>
      ))}
      <Button
        className="p-0 text-xs p-button-text flex gap-2 "
        onClick={onNext}
      >
        <Translate>Next</Translate>
        <i className="pi pi-chevron-right text-xs" />
      </Button>
    </div>
  );
}
