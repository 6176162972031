import React from "react";

import AnalyticsDetails from "../components/analytics/AnalyticsDetails";
import ChangeDistribution from "../components/analytics/ChangeDistribution";
import Footer from "../components/layouts/Footer";
import Navbar from "../components/layouts/Navbar";
import { Translate } from "react-auto-translate";
import { TabMenu } from "primereact/tabmenu";
import Document from "../components/documents/Documents";

import { analyticsData } from "../data/data";
import { useSettingsStore } from "../zustand/useSettings";

export default function AnalyticsPage() {
  const [selected, setSelected] = React.useState(analyticsData[0].distribution);
  const [distribution, setDistribution] = React.useState(analyticsData[0]);
  const [options, setOptions] = React.useState(analyticsData[0].options);

  const [tab, setTab] = React.useState(0);

  const {lang} = useSettingsStore()

  const items = [
    { label: <Translate>Analytics & Chart</Translate> },
    { label: <Translate>Documents</Translate> },
  ];

  // console.log(distribution);

  return (
    <main className="flex flex-column ">
      <div className="flex flex-column w-full relative mb-4 py-3 px-3 md:px-5 lg:px-8">
        <Navbar />
      </div>
      <div className="mt-2 mb-5 md:my-5 lg:my-6 text-center w-11 lg:w-8 align-self-center px-5 md:px-0">
        <strong className="text-3xl  md:text-5xl ">
          <Translate>
            Explore our data and documentation on green initiatives in Africa
          </Translate>
        </strong>
      </div>

      <div className="mb-5 w-11 lg:w-7 align-self-center">
        <TabMenu
          model={items}
          activeIndex={tab}
          onTabChange={(e) => setTab(e.index)}
          className='text-base md:text-lg lg:text-xl'
        />
      </div>

      <div className="w-11 lg:w-9 align-self-center">
        {tab === 0 ? (
          <div>
            <ChangeDistribution
              selected={selected}
              setSelected={setSelected}
              setDistribution={setDistribution}
              analyticsData={analyticsData}
              setOptions={setOptions}
            />

            <AnalyticsDetails
              title={selected}
              data={distribution}
              options={options}
            />
          </div>
        ) : (
          <div>
            <Document />
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
}
