import React from "react";

// sections
import ContactLink from "./ContactLink";
import SendMessage from "./SendMessage";
import LogoStatement from "./LogoStatement";
import Copyright from "./Copyright";
import Sponsors from "./Sponsors";

export default function Footer() {
  return (
    <section className="bg-primary-100 flex flex-column justify-content-center align-items-center py-6 px-0 md:px-5">
      <div className="grid p-1 w-11 gap-3 grid-nogutter">
        <LogoStatement />
        <ContactLink />
        <SendMessage />
      </div>
      <div className="grid p-1 w-11 gap-3 grid-nogutter align-items-center mt-5">
        <Copyright />
        <Sponsors />
      </div>
    </section>
  );
}
