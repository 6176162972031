import React from "react";

import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import SocialSlide from "./SocialSlide";

export default function Social() {

  const [swiperInstance, setSwiperInstance] = React.useState();

  const social = [
    {
      name: "Bernard Agojie",
      business: "Bosome Franchise",
      comment:
        "The JUSTIS digital portal provides a platform for entrepreneurs, policymakers and investors",
      id: 0,
      image: "social",
      imageSm: "socialsm",
    },
    {
      name: "Joseline Adibrosu",
      business: "Etheral Fragrance",
      comment:
        "The JUSTIS digital portal helps me as a business owner to connect with other entrepreneurs and investors in the green energy sector.",
      id: 1,
      image: "social",
      imageSm: "socialsm",
    },
    {
      name: "Fred Adu Twum",
      business: "Stellar Tech",
      comment:
        "The JUSTIS digital portal has helped me understand what policy makers are doing in the green energy sector.",
      id: 2,
      image: "social",
      imageSm: "socialsm",
    },
  ];

  return (
    <Swiper
      className="flex flex-column"
      modules={[Autoplay, EffectFade]}
      effect="fade"
      grabCursor={true}
      spaceBetween={30}
      slidesPerView={1}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
      }}
      onSwiper={(swiper) => {
        setSwiperInstance(swiper);
      }}
    >
      {social.map((i, _) => (
        <SwiperSlide key={_}>
          <SocialSlide
            image={i.image}
            imageSm={i.imageSm}
            name={i.name}
            business={i.business}
            comment={i.comment}
            id={i.id}
            swiper={swiperInstance}
            index={_}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
