import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// pages
import Home from "../pages/Home";
import About from "../pages/About";
import Directory from "../pages/Directory";
import Analytics from "../pages/Analytics";
import Gallery from "../pages/Gallery";
import News from "../pages/News";
import Events from "../pages/Events";
import ReactGA from "react-ga";

export default function RootRoute(props) {

  const location = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);



  return (
    <div className="relative">
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/resources" element={<Analytics />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/media">
          <Route path="news" element={<News />} />
          <Route path="events" element={<Events />} />
          <Route path="gallery" element={<Gallery />} />
        </Route>
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  );
}
