import { Divider } from "primereact/divider";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { Translate } from "react-auto-translate";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useBreakpoints } from "react-breakpoints-hook";

export default function SocialSlide({
  image,
  imageSm,
  swiper,
  name,
  comment,
  business,
  id,
  index,
}) {
  let { xs, sm, md, lg, xlg } = useBreakpoints({
    sm: {
      min: 0,
      max: 767,
    },
    md: {
      min: 768,
      max: null,
    },
  });

  return (
    <section
      className={`${
        sm ? imageSm : image
      }  px-0 py-8  flex justify-content-center`}
    >
      <div className="w-11 py-5 flex flex-column md:flex-row align-items-end gap-2 text-white">
        <div className="w-12 md:w-6 md:p-5 pr-8 mb-5 md:mb-0">
          {/* name  and controls*/}
          <div className="flex justify-content-between align-items-center">
            <strong className="text-2xl md:text-4xl">
              <Translate>{name}</Translate>
            </strong>
            <div className="hidden md:flex gap-3 align-items-center">
              <Button
                className={`p-button-sm p-button-outlined text-white border-circle m-0 p-2 
                ${id > 0 && "bg-white-alpha-50"}`}
                onClick={() => swiper.slidePrev()}
              >
                <i className="pi pi-chevron-left text-sm" />
              </Button>
              <Button
                className={`p-button-sm p-button-outlined text-white  border-circle m-0 p-2
                ${id < 2 && "bg-white-alpha-50"}
                `}
                onClick={() => swiper.slideNext()}
              >
                <i className="pi pi-chevron-right text-sm" />
              </Button>
            </div>
          </div>
          {/* business */}
          <div>
            <em className="text-sm">
              <Translate>{business}</Translate>
            </em>
          </div>
          {/* comments */}
          <div className="my-4 md:my-5 font-medium">
            <Translate>{comment}</Translate>
          </div>
          {/* pagination */}
          <div className="flex justify-content-between align-items-center">
            <div className="flex gap-3">
              <div
                className={`${
                  id === 0
                    ? "bg-primary-500 border-circle shadow-1 border-1"
                    : "border-white border-1 border-circle"
                }`}
                style={{ padding: 8 }}
              />
              <div
                className={`${
                  id === 1
                    ? "bg-primary-500 border-circle shadow-1 border-1"
                    : "border-white border-1 border-circle"
                }`}
                style={{ padding: 8 }}
              />
              <div
                className={`${
                  id === 2
                    ? "bg-primary-500 border-circle shadow-1 border-1"
                    : "border-white border-1 border-circle"
                }`}
                style={{ padding: 8 }}
              />
            </div>
            <div className="flex md:hidden gap-3 align-items-center">
              <Button
                className={`p-button-sm p-button-outlined text-white border-circle m-0 p-2 
                ${id > 0 && "bg-white-alpha-50"}`}
                onClick={() => swiper.slidePrev()}
              >
                <i className="pi pi-chevron-left text-sm" />
              </Button>
              <Button
                className={`p-button-sm p-button-outlined text-white  border-circle m-0 p-2
                ${id < 2 && "bg-white-alpha-50"}
                `}
                onClick={() => swiper.slideNext()}
              >
                <i className="pi pi-chevron-right text-sm" />
              </Button>
            </div>
          </div>
        </div>
        <Divider className="md:hidden" />
        <Divider className="hidden md:flex" layout="vertical" />
        <div className="w-12 md:w-6 md:p-5 pr-8 text-white flex flex-column gap-5">
          <strong className="text-2xl md:text-4xl">
            <Translate>
              Join the social network of green entrepreneurs, policymakers and
              investors.
            </Translate>
          </strong>
          <div className="text-base">
            <Translate>
              The JUSTIS digital portal provides a platform for entrepreneurs,
              policymakers and investors to connect in an online marketplace to
              retail green energy products and services.
            </Translate>
          </div>
          <div>
            <Button
              className="border-noround"
              onClick={() =>
                window.open("https://social.justis.africa/")
              }
            >
              <span>
                <Translate>Join Justis Social</Translate>
              </span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
