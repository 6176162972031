import React from 'react'
import { Translate } from "react-auto-translate";

export default function Copyright() {
  return (
    <div className="col-12 lg:col-8 flex gap-6 flex-order-1 md:flex-order-0">
      <div className="text-sm md:text-base font-light">
        <Translate>Copyright © Justis Digital</Translate>
      </div>

      <div className="text-sm md:text-base font-light">
        <Translate>Privacy Policy</Translate>
      </div>
    </div>
  );
}
