import { Button } from "primereact/button";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Translate } from "react-auto-translate";
import MoreButton from "../buttons/MoreButton";
import Wrapper from "../containers/Wrapper";

export default function About() {
  const navigate = useNavigate();

  return (
    <Wrapper className="flex flex-row flex-wrap w-11 gap-5 align-items-center">
      <div className="w-12 lg:w-6 home-about h-20rem md:h-25rem lg:h-30rem flex-order-1 lg:flex-order-0"></div>
      <div className="w-12 lg:w-6 flex flex-column gap-3 col lg:p-4 ">
        <strong className="text-2xl md:text-4xl lg:text-5xl">
          <Translate>
            Bridging the gap between green entrepreneurs, policymakers and
            investors.
          </Translate>
        </strong>
        <div className="text-sm md:text-base line-height-3">
          <Translate>
            Accelerating the green energy transition requires smart energy
            solutions. The JUSTIS digital portal provides a platform for
            entrepreneurs, policymakers and investors to connect in an online
            marketplace to retail green energy products and services. The portal
            provides data on informal sector workers and green entrepreneurs in
            Africa. Explore the data by country or theme.
          </Translate>
        </div>
        <div>
          <MoreButton text={"Learn More"} onClick={() => navigate("/about")} />
        </div>
      </div>
    </Wrapper>
  );
}
