import React from "react";
import { Image } from "primereact/image";
import defaultImage from "../../assets/news/news.jpeg";
import { Translate } from "react-auto-translate";
import { Badge } from "primereact/badge";
import { useContentfulNews } from "../../hooks/useContentfulNews";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import empty from "../../assets/news/empty.svg";

export default function NewsSegment() {
  const [news, setNews] = React.useState();
  const { getNews } = useContentfulNews();

  React.useEffect(() => {
    getNews().then((response) => setNews(response));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (news?.length === 0)
    return (
      <div className="my-5 flex flex-column w-full align-items-center">
        <Image src={empty} className="my-5" />
        <div>
          <Translate>We currently have no records for your search</Translate>
        </div>
      </div>
    );

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 1300: 3 }}>
      <Masonry gutter={"30px"} className="my-5">
        {news?.map((item, index) => {
          return (
            <a
              href={item.url}
              key={index}
              target="_blank"
              rel="noreferrer"
              className="text-color no-underline"
              style={{
                width: "100%",
                display: "block",
              }}
            >
              <NewsItem key={index} item={item} />
            </a>
          );
        })}
      </Masonry>
    </ResponsiveMasonry>
  );
}

// News items
const NewsItem = ({ item }) => {
  return (
    <div className="w-full">
      <Image
        src={item?.imageUrl ?? defaultImage}
        imageClassName="h-20rem md:h-25rem"
        imageStyle={{
          objectFit: "cover",
          width: "100%",
          display: "block",
        }}
      />

      <div className="w-full">
        <div className="flex gap-2 md:gap-3 align-items-center  mt-3">
          <Badge
            value={<Translate>News</Translate>}
            className="border-round-2xl text-xs md:text-sm bg-primary-300 text-color px-3 md:hidden"
            size="small"
          />
          <Badge
            value={<Translate>News</Translate>}
            className="border-round-2xl text-xs md:text-sm bg-primary-300 text-color px-3 hidden md:flex"
            size="large"
          />
          <div className="text-xs md:text-base font-light">
            <Translate>
              {new Date(item?.date).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </Translate>
          </div>
        </div>
        <p className="text-base md:text-lg lg:text-xl mt-3 mb-0 font-semibold">
          <Translate>{item?.title}</Translate>
        </p>
      </div>
    </div>
  );
};
