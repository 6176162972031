import React from "react";

// sections
import Banner from "../components/about/Banner";
import Content from "../components/about/Content";
import Footer from "../components/layouts/Footer";

export default function About() {
  return (
    <main>
      
      <Banner />
      <Content />
      <Footer/>
    </main>
  );
}



