import React from "react";
import { Image } from "primereact/image";
import { Translate } from "react-auto-translate";
import { useBreakpoints } from "react-breakpoints-hook";

// images
import auckley from "../../assets/team/AuckleySiabowa.JPG";
import mwansa from "../../assets/team/MwansaKaoma.jpg";
import kayofa from "../../assets/team/LilongeniKayofa.jpg";
import ferde from "../../assets/team/FerdinandTornyie.jpg";
import angie from "../../assets/team/AngelaKorkor.jpg";
import kevin from "../../assets/team/kevin.jpeg";
import elie from "../../assets/team/elie.jpg";

export default function MeetTeam() {
  const team = [
    {
      name: "Auckley Siabowa",
      title: "Research Associate",
      image: auckley,
      bio: "Biography",
    },
    {
      name: " Mwansa Kaoma",
      title: "Researcher",
      image: mwansa,
      bio: "Biography",
    },
    {
      name: "Lilongeni Kayofa",
      title: "Researcher",
      image: kayofa,
      bio: "Biography",
    },
    {
      name: "Dr. Ferdinand Tornyie",
      title: "Research Fellow ",
      image: ferde,
      bio:
        "Dr. Ferdinand Tornyie is a Research Fellow at the United Nations University-Institute for " +
        "Natural Resources in Africa (UNU-INRA). He has PhD in Environmental Science, from " +
        "University of Ghana. He has extensive experience as a Research Scientist, a trainer, an " +
        "entrepreneur, project developer and manager. He has expertise and research interest in clean " +
        "energy solutions and climate change mitigation, with emphasis on clean cooking technologies " +
        "and testing, just energy transition and social behaviour change toward new technologies. He " +
        "has several years of experience working as a Research Scientist at Council for Scientific and " +
        "Industrial Research-Institute of Industrial Research, Ghana. He has also served as an adjunct " +
        "lecturer/consultant, at the University of Cape Coast-Department of Conservation Biology and " +
        "Entomology, Ghana. He has served as consultant to several institutions including government " +
        "agencies (eg. Ghana Exim Bank & Ministry of Education- COTVET), private companies and " +
        "international organization such as ENI-Ghana SNV and World Bank-Ghana.",
    },
    {
      name: "Angela Korkor Apperkon",
      title: "Research Associate",
      image: angie,
      bio:
        "Angela Korkor Apperkon is an MPhil Environmental Science year two student at the Institute for " +
        "Environment and Sanitation Studies (IESS), University of Ghana. She is a research associate with the " +
        "United Nations University- Institute for Natural Resources in Africa (UNU-INRA) on the JUSTIS project, " +
        "which focuses on the Just Energy Transition in the Informal Sector in Ghana, mainly the informal food processing," +
        " metal manufacturing, wood manufacturing and the oil and gas sectors.",
    },
    {
      name: "Aboua Angui Christian",
      title: "Research Associate",
      image: kevin,
      bio:
        "Christian Aboua holds a PhD in Economics from the Félix Houphouët-Boigny Univers" +
        "ity in Abidjan, Côte d'Ivoire. He works as a lecturer and researcher at the Univ" +
        "ersity Jean Lorougnon Guédé of Daloa. He is also a researcher at the CIRES Unit " +
        "for the Analysis of Economic Policies (CAPEC). He conducts research on energy ef" +
        "ficiency, energy transition, and structural transformation issues. He is complet" +
        "ing a Post-Doc within the framework of the JUSTIS project, which focuses on the " +
        "just energy transition in Côte d'Ivoire's food and manufacturing sectors",
    },
    {
      name: "Krah Kouakou",
      title: "Research Associate",
      image: elie,
      bio:
        "KRAH Emmanuel is a student in Master 2 Research in Development Economics at the " +
        "University Alassane Ouattara, Bouaké, Côte d'Ivoire. He is currently a research " +
        "assistant at the Abidjan Operational Unit of the United Nations University for N" +
        "atural Resources within the framework of the JUSTIS project. He is preparing his" +
        " Master's thesis on the just energy transition in the informal sector.",
    },
  ];

  return (
    <section className="w-12 my-7 flex flex-column gap-4">
      <strong className="text-2xl md:text-4xl lg:text-5xl">
        <Translate>Meet the team</Translate>
      </strong>
      <div className="grid grid-nogutter gap-2 md:gap-3 lg:gap-4">
        {team.map((member, index) => (
          <TeamItem
            key={index}
            name={member.name}
            title={member.title}
            image={member.image}
            bio={member.bio}
          />
        ))}
      </div>
    </section>
  );
}

// team item
export const TeamItem = ({ name, title, image, bio }) => {
  let { xxs, xs, sm, md, lg } = useBreakpoints({
    xxs: {
      min: 0,
      max: 320,
    },
    xs: {
      min: 321,
      max: 560,
    },
    sm: {
      min: 561,
      max: 960,
    },
    md: {
      min: 961,
      max: 1300,
    },
    lg: {
      min: 1301,
      max: null,
    },
  });

  return (
    <div
      className={`${
        xxs
          ? "h-10rem w-10rem"
          : xs
          ? "h-12rem w-10rem"
          : sm
          ? "h-17rem w-15rem m-1"
          : md
          ? "h-16rem w-16rem"
          : lg
          ? "h-20rem w-20rem"
          : ""
      } relative`}
    >
      <Image
        src={image}
        alt={name}
        imageClassName="w-full h-full team"
        imageStyle={{
          objectFit: "cover",
        }}
      />
      <div
        className="team absolute  flex align-items-end px-3 pb-2"
        style={{
          zIdex: 1,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div className="flex flex-column gap-1 mb-1 md:mb-2 lg:mb-3">
          <div
            className={`font-semibold text-white line-height-0`}
            style={{
              fontSize: xxs
                ? ".8rem"
                : xs
                ? ".8rem"
                : sm
                ? "1rem"
                : md
                ? "1.1rem"
                : lg
                ? "1.2rem"
                : "",
            }}
          >
            {name}
          </div>
          <em
            className="text-white m-0 line-height-0"
            style={{
              fontSize: xxs
                ? ".5rem"
                : xs
                ? ".5rem"
                : sm
                ? ".7rem"
                : md
                ? ".8rem"
                : lg
                ? ".9rem"
                : "",
            }}
          >
            <Translate>{title}</Translate>
          </em>
        </div>
      </div>
    </div>
  );
};
