import React from "react";
import Navbar from "../layouts/Navbar";
import { Button } from "primereact/button";
import { HiChartBar } from "react-icons/hi";
import { Translate } from "react-auto-translate";
import { useBreakpoints } from "react-breakpoints-hook";
import { useNavigate } from "react-router-dom";
import SwipperController from "./SwipperController";
import { useThreshold } from "../../hooks/useThreshold";

export default function SwiperContent() {
  let { xxs, xs, sm, md, lg } = useBreakpoints({
    xxs: {
      min: 0,
      max: 320,
    },
    xs: {
      min: 321,
      max: 560,
    },
    sm: {
      min: 561,
      max: 960,
    },
    md: {
      min: 961,
      max: 1300,
    },
    lg: {
      min: 1301,
      max: null,
    },
  });

  const navigate = useNavigate();
  const threshold = useThreshold(360);

  return (
    <div
      className={`h-full relative  ${
        xxs ? "" : xs ? "px-2" : sm ? "px-3" : md ? "" : lg ? "px-3" : ""
      }`}
    >
      <div className="z-10">
        <Navbar textColor={"text-white"} />
      </div>

      <div className="h-full relative flex flex-column justify-content-end  relative">
        <div
          className={` 
         ${threshold && "mb-3"}
          font-bold text-white line-height-2 dontFuckingOverlap`}
          style={{
            width: xxs
              ? "calc(5ch + 50vw)"
              : xs
              ? "calc(5ch + 45vw)"
              : sm
              ? "calc(10ch + 30vw)"
              : md
              ? "calc(10ch + 25vw)"
              : lg
              ? "calc(10ch + 25vw)"
              : "",
            fontSize: xxs
              ? "calc(1rem + 1vh)"
              : xs
              ? "calc(1.1rem + 2.5vh)"
              : sm
              ? "calc(1.2rem + 3vh)"
              : md
              ? "calc(1.3rem + 4vh)"
              : lg
              ? "calc(1.3rem + 4vh)"
              : "",
          }}
        >
          <Translate>
            Accelerating Clean Energy Transitions in the Informal Sector.
          </Translate>
        </div>
        <div
          className={` 
          ${threshold && "hidden"}
          text-white mt-4 mb-5 md:mb-4 lg:mb-7 `}
          style={{
            width: xxs
              ? "calc(16ch + 50vw)"
              : xs
              ? "calc(15ch + 50vw)"
              : sm
              ? "calc(17ch + 50vw)"
              : md
              ? "calc(17ch + 50vw)"
              : lg
              ? "calc(10ch + 50vw)"
              : "",
            fontSize: xxs
              ? "calc(.2rem + 1vh)"
              : xs
              ? "calc(.2rem + 1vh)"
              : sm
              ? "calc(.3rem + 1vh)"
              : md
              ? "calc(.3rem + 1.3vh)"
              : lg
              ? "calc(.3rem + 1.4vh)"
              : "",
          }}
        >
          <Translate>
            The JUSTIS portal provides a platform for entrepreneurs,
            policymakers and investors to connect in an online marketplace to
            retail green energy products and services. It also provides data on
            informal sector workers and green entrepreneurs in Africa
          </Translate>
        </div>

        <div className="flex gap-4 relative mb-3 md:mb-5 flex-wrap">
          <div className="flex flex-wrap gap-2 md:gap-3 lg:gap-4">
            <Button
              className="p-button-sm border-noround flex justify-content-center text-xs md:text-base"
              onClick={() => window.open("https://social.justis.africa/")}
            >
              <span>
                <Translate>Join Justis Social</Translate>
              </span>
            </Button>
            <Button
              className="p-button-sm border-noround p-button-outlined text-white flex justify-content-center gap-2 mr-4 text-xs md:text-base"
              style={{
                paddingLeft: "1.15rem",
                paddingRight: "1.15rem",
              }}
              onClick={() => navigate("/resources")}
            >
              <HiChartBar />
              <span>
                <Translate>Explore Data</Translate>
              </span>
            </Button>
          </div>
        </div>
        <div
          // className={` ${
          //   xxs
          //     ? "bg-red-300"
          //     : xs
          //     ? "bg-green-300"
          //     : sm
          //     ? "bg-blue-300"
          //     : md
          //     ? "bg-pink-300"
          //     : lg
          //     ? "bg-yellow-500"
          //     : "text-xs"
          // }`}
          style={{
            height: xxs
              ? "calc(6vh)"
              : xs
              ? "calc(6vh)"
              : sm
              ? "calc(11vh)"
              : md
              ? "calc(13vh)"
              : lg
              ? "calc(15vh)"
              : "",
          }}
        />
      </div>
    </div>
  );
}
