import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Menu as DropdownMenu } from "primereact/menu";
import { Translate } from "react-auto-translate";

export default function Menu({
  to,
  name,
  hasSubmenu,
  subMenu,
  textColor = "text-gray-900",
}) {
  const activeClassName = "text-primary cursor-pointer";
  const inactiveClassName = `${textColor} transition-all no-underline cursor-pointer transition-duration-500  hover:underline hover:text-primary menu white-space-nowrap overflow-hidden text-overflow-clip`;
  const mediaInactiveClassName = `${textColor} transition-all no-underline cursor-pointer white-space-nowrap overflow-hidden text-overflow-clip`;
  const navigate = useNavigate();
  const menuRef = React.useRef(null);
  const { pathname } = useLocation();

  let items = [
    {
      label: <Translate>News & Stories</Translate>,
      command: () => {
        navigate("/media/news");
      },
    },
    {
      label: <Translate>Events</Translate>,
      command: () => {
        navigate("/media/events");
      },
    },
    {
      label: <Translate>Gallery</Translate>,
      command: () => {
        navigate("/media/gallery");
      },
    },
  ];

  const isMedia = () => {
    if (pathname.includes("media")) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      {/* render normal div for media and navlink for other menu */}
      {to === "media" ? (
        <div
          to={to}
          className={isMedia() ? activeClassName : mediaInactiveClassName}
          onClick={(e) => hasSubmenu && menuRef.current.toggle(e)}
         
        >
          <Translate>{name}</Translate>{" "}
          {hasSubmenu && <i className="pi pi-chevron-down text-xs" />}
        </div>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) =>
            isActive ? activeClassName : inactiveClassName
          }
        >
          <Translate>{name}</Translate>{" "}
          {hasSubmenu && <i className="pi pi-chevron-down text-xs" />}
        </NavLink>
      )}
      {/* dropdown menuƒ */}
      <DropdownMenu
        model={items}
        popup
        ref={menuRef}
        className="border-noround text-sm py-2 mt-1"
        onMouseLeave={(e) => hasSubmenu && menuRef.current.toggle(e)}
      />
    </React.Fragment>
  );
}
