export const analyticsData = [
  // SECTOR BY ACTIVITY
  {
    distribution: "Sector By Activity",
    options: {
      type: "pie",
      optionType: "",
      id: "gender",
      filter: "All",
      percent: false,
      tableOnly: true,
      showTable: false,
      showChart: false,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          "Food Processing": [
            " Local and continental dishes",
            "Local food",
            "Khebab",
            "Boiled and roasted snacks",
            "Breakfast",
            "Bakery",
            "Fast food",
            "Fried snacks",
            "Continental foods",
          ],
          Manufacturing: [
            "Fabrication",
            "Carpentry",
            "Cloth manufacturers",
            "Hair/hair accesory makers",
            "Carpentry, Joinery",
            "Car wash",
            "Spraying",
            "Grinding/Milling",
            "Laundry",
          ],
        },
      },
    ],
  },
  // INFORMAL BUSINESS PER COUNTRY
  {
    distribution: "Informal Business by Country",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "i_b_b_c",
      filter: "All",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          Ghana: 806,
          Zambia: 477,
          Namibia: 200,
        },
      },
    ],
  },
  // BUSINESS VENTURE TYPES
  {
    distribution: "Business Venture by Country",
    options: {
      type: "pie",
      optionType: "",
      id: "sex",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Food Processing": 922,
          Manufacturing: 557,
        },
      },
      {
        dataOn: "Ghana",
        data: {
          Female: 402,
          Male: 402,
        },
      },
      {
        dataOn: "Zambia",
        data: {
          Female: 373,
          Male: 102,
        },
      },
      {
        dataOn: "Namibia",
        data: {
          Female: 147,
          Male: 53,
        },
      },
    ],
  },
  // SEX BY COUNTRY BY SECTOR
  {
    distribution: "Sex By Country By Sector",
    options: {
      type: "pie",
      optionType: "",
      id: "sex",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Female: 48,
          Male: 52,
        },
      },
      {
        dataOn: "Ghana - All Sectors",
        data: {
          Female: 40,
          Male: 60,
        },
      },
      {
        dataOn: "Ghana - Manufacturing",
        data: {
          Female: 3,
          Male: 97,
        },
      },
      {
        dataOn: "Ghana - Food Processing",
        data: {
          Female: 78,
          Male: 22,
        },
      },
      {
        dataOn: "Cote d'Ivoire - All Sectors",
        data: {
          Female: 54,
          Male: 46,
        },
      },
      {
        dataOn: "Cote d'Ivoire - Manufacturing",
        data: {
          Female: 15,
          Male: 85,
        },
      },
      {
        dataOn: "Cote d'Ivoire - Food Processing",
        data: {
          Female: 71,
          Male: 29,
        },
      },
      {
        dataOn: "Namibia - All Sectors",
        data: {
          Female: 40,
          Male: 60,
        },
      },
      {
        dataOn: "Namibia - Manufacturing",
        data: {
          Female: 48,
          Male: 52,
        },
      },
      {
        dataOn: "Namibia - Food Processing",
        data: {
          Female: 37,
          Male: 53,
        },
      },
      {
        dataOn: "Zambia - All Sectors",
        data: {
          Female: 56,
          Male: 44,
        },
      },
      {
        dataOn: "Zambia - Manufacturing",
        data: {
          Female: 7,
          Male: 93,
        },
      },
      {
        dataOn: "Zambia - Food Processing",
        data: {
          Female: 70,
          Male: 30,
        },
      },
    ],
  },
  // AGE
  {
    distribution: "Age",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "age",
      filter: "All",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showSector: false,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          "50 and above": 16.1,
          "40-49": 33.1,
          "30-39": 35.8,
          "20-29": 13.9,
          "Below 20	": 1.1,
        },
      },
    ],
  },
  // EDUCATION
  {
    distribution: "Education",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "education",
      filter: "Female",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showSector: false,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Female",
        data: {
          "Primary	": 33,
          "Junior/JHS": 40,
          "Secondary/SHS": 23,
          "Higher Degree (HND, Degree, Postgraduate)": 3,
          "No formal education": 1,
          "Vocational Training": 0,
          Other: 0,
        },
      },
      {
        dataOn: "Male",
        data: {
          "Primary	": 17,
          "Junior/JHS": 31,
          "Secondary/SHS": 42,
          "Higher Degree (HND, Degree, Postgraduate)": 7,
          "No formal education": 1,
          "Vocational Training": 2,
          Other: 1,
        },
      },
    ],
  },
  // REGISTERED
  {
    distribution: "Registered",
    options: {
      type: "pie",
      optionType: "",
      className: "",
      id: "business_venture",
      filter: "All",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showSector: false,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          No: "73",
          Yes: "27",
        },
      },
    ],
  },
  // LAND OWNERSHIP
  {
    distribution: "Land Ownership(Do you own a land you operate on)",
    options: {
      type: "pie",
      optionType: "",
      className: "",
      id: "business_venture",
      filter: "Male",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showSector: false,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Male",
        data: {
          No: 37,
          Yes: 12,
        },
      },
      {
        dataOn: "Female",
        data: {
          No: 40,
          Yes: 11,
        },
      },
    ],
  },
  // WEEKLY BUSINESS INCOME
  {
    distribution: "Weekly Business Income",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "weekly_income",
      filter: "Female",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showSector: false,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Female",
        data: {
          "Less than $50": 20.16,
          "$50 - $100": 15.81,
          "$100 - $150": 6.65,
          "$150 - $200": 3.19,
          "$200 and above": 2.7,
          "$31-$63": 0.89,
          "$126 and above": 0.4,
          "$31-$94": 0.32,
          "$50-$100": 0.32,
          "Less than $500": 0.12,
          "$2000 and above": 0.12,
          "$500 - $1000": 0.12,
          "$94-$126": 0.08,
          "$1000 - $1500": 0.04,
        },
      },
      {
        dataOn: "Male",
        data: {
          "Less than $50": 10.89,
          "$50 - $100": 19.03,
          "$100 - $150": 0.12,
          "$150 - $200": 3.79,
          "$200 and above": 4.52,
          "$31-$63": 0.69,
          "$126 and above": 1.29,
          "$31-$94": 0.24,
          "$50-$100": 0.24,
          "Less than $500": 0.12,
          "$2000 and above": 0.08,
          "$500 - $1000": 0.04,
          "$94-$126": 0.16,
          "$1000 - $1500": 0.24,
          "$1500-$2000": 0.08,
        },
      },
    ],
  },
  // TRADE UNION
  {
    distribution: "Trade Union",
    options: {
      type: "pie",
      optionType: "",
      id: "sex",
      filter: "All",
      percent: false,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          No: 1594,
          Yes: 208,
        },
      },
    ],
  },
  // MORDERN ENERGY
  {
    distribution: "Energy used",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "modern_energy",
      filter: "Overall",
      percent: false,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Electricity: 5317,
          Gas: 2051,
          Biogas: 131,
          None: 27,
          Solar: 20,
          Diesel: 17,
        },
      },
      {
        dataOn: "Food Processing",
        data: {
          Electricity: 2393,
          Gas: 1625,
          Biogas: 108,
          None: 27,
          Solar: 20,
          RAS: 8,
          Generator: 6,
          Diesel: 5,
          "Only use charcoal": 3,
        },
      },
      {
        dataOn: "Manufacturing",
        data: {
          Electricity: 2922,
          Gas: 426,
          Biogas: 23,
          Diesel: 12,
          Fuel: 9,
          Electrode: 9,
        },
      },
    ],
  },
  // AFFORDABILITY
  {
    distribution: "Affordability of energy sources by type of business venture",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "affordability",
      filter: "Food Processing",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Food Processing",
        data: {
          Affordable: 33.55,
          "Fairly Affordable": 29.22,
          "Not Affordable": 33.23,
        },
      },
      {
        dataOn: "Manufacturing",
        data: {
          Affordable: 27.36,
          "Fairly Affordable": 41.18,
          "Not Affordable": 30.46,
        },
      },
    ],
  },
  // EXPENDITURE
  {
    distribution: "Percentage of business expenditure on energy",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "affordability",
      filter: "Food Processing",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Food Processing",
        data: {
          "10-20%": 42.13,
          "20-30%": 24.61,
          "30-40%": 13.42,
          "40-50%": 3.5,
          "50% and above": 0.89,
          "Less than 10%": 15.44,
        },
      },
      {
        dataOn: "Manufacturing",
        data: {
          "10-20%": 34.41,
          "20-30%": 24.29,
          "30-40%": 10.66,
          "40-50%": 7.42,
          "50% and above": 2.7,
          "Less than 10%": 20.51,
        },
      },
    ],
  },
  // CHALLENGES
  {
    distribution: "Challenges operating as an informal business",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "challenges",
      filter: "All",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "All",
        data: {
          "Financial problems (high capital cost, lack of access to loan, no support)": 33,
          "High cost of materials/goods/food": 19,
          "High cost of energies (electricity)": 10,
          "High competition": 7,
          "Customer problems (disputes, bullying, demanding discount, dishonesty)": 6,
          "Exposure to thefts and robberies": 6,
          "Few customers (sometimes)": 5,
          "Scarcity of materials": 5,
          "Harrasement/threat (mandatory tax, eviction)": 5,
          "Lack of (state) support": 4,
        },
      },
    ],
  },
  // DIRTY FUEL
  {
    distribution: "What happens if you can no longer use dirty fuels",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "capital_source",
      filter: "Overall",
      percent: false,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Business will continue": 973,
          "Business will collapse": 783,
          "Will shift to clean energy": 324,
          "Will shift to alternative energies": 230,
          "N/A": 69,
          "Will change business": 59,
          "Slow production": 48,
          "Nothing will happen": 45,
          "I don’t use energy for my business": 26,
          " if it is not expensive": 25,
        },
      },
      {
        dataOn: "Food Processing",
        data: {
          "Business will continue": 520,
          "Business will collapse": 411,
          "Will shift to clean energy": 240,
          "Will shift to alternative energies": 211,
          "N/A": 46,
          "Will change business": 45,
          "Slow production": 24,
          "Nothing will happen": 21,
          "I don’t use energy for my business": 17,
          " if it is not expensive": 11,
        },
      },
      {
        dataOn: "Manufacturing",
        data: {
          "Business will continue": 562,
          "Business will collapse": 263,
          "Will shift to clean energy": 84,
          "Will shift to alternative energies": 48,
          "N/A": 24,
          "Will change business": 21,
          "Slow production": 19,
          "Nothing will happen": 13,
          "I don’t use energy for my business": 9,
          " if it is not expensive": 8,
        },
      },
    ],
  },

  // KNOWLEDGE ABOUT ENERGY TRANSITION
  {
    distribution: "What do you use electricity for in your business",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: false,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: true,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Lighting: 2380,
          "Charging cell phones": 1740,
          Refrigeration: 961,
          Fabrication: 884,
          "Playing music": 694,
          "Cooking/heating": 541,
          Manufacturing: 157,
          "Don't use electricity": 97,
          "operate appliance/machine": 54,
          Milling: 29,
        },
      },
      {
        dataOn: "Food Processing",
        data: {
          Lighting: 31.71,
          Refrigeration: 22.04,
          "Charging cell phones": 19.53,
          "Cooking/heating	": 11.96,
          "Playing music": 10.49,
          "Don't use electricity": 2.34,
          Milling: 0.7,
          Television: 0.48,
          RAS: 0.43,
          Grinding: 0.31,
        },
      },
      {
        dataOn: "Manufacturing",
        data: {
          Lighting: 30.82,
          "Charging cell phones": 26.94,
          Fabrication: 25.61,
          "Playing music": 7.5,
          Manufacturing: 4.46,
          "operate appliance/machine": 1.36,
          Refrigeration: 1.33,
          "Cooking/heating": 1.3,
          Grinding: 0.38,
          Fan: 0.29,
        },
      },
    ],
  },
  // WHAT SHOULD GOVERNMENT DO
  {
    distribution: "What should government do?",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Sensitization/awareness/education on clean energies": 35,
          "Make clean energy affordable/cheap (er)": 19,
          "Financial support (loans)to expand and enlarge businessness": 17,
          "Reduce (subsidize) the cost of energies (electricty, gas, clean energies)": 11,
          "Engage the informal sector to understand their needs and challenges": 5,
          "Make (affordable) clean energy accessible": 4,
          "Support (subsidize) with clean energy tools/materials/machines": 4,
          "Make clean energy available": 2,
          "Training (waste recycling)/emporwerment": 2,
          "Reduce the cost of materials/goods/food": 1,
        },
      },
    ],
  },
  // ADVANTAGES OF WOOD
  {
    distribution: "What are the advantages of using wood",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Cheap (er)": 47,
          "Effective (strong heat)": 20,
          "Chairs making": 7,
          Reliable: 7,
          "Useful/essential/adapted/suitable for activity": 7,
          Accessible: 7,
          "Easy to use": 7,
        },
      },
    ],
  },
  // ADVANTAGES OF CHARCOAL
  {
    distribution: "What are the advantages of using charcoal",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Affordable/cheaper": 34,
          "(Readily) Available  (onsite)": 20,
          "Cooks/grills better/well": 8,
          "Cooks fast(er)": 8,
          "Last longer": 6,
          Accessible: 6,
          "Easy to use": 6,
          "Adapted/suitable for the activity": 5,
          "Good substitute (to gas)": 4,
          Reliable: 3,
        },
      },
    ],
  },
  // DISADVANTAGES OF CHARCOAL
  {
    distribution: "What are the disadvantages of using charcoal",
    options: {
      type: "bar",
      optionType: "horizontal",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Dirty: 29,
          "risky to health/pollution (from smoke)": 25,
          Expensive: 12,
          "Price flactuates/(becoming) expensive": 10,
          Slow: 9,
          "Burns out quiclky": 4,
          "Waste time": 3,
          "Shortage/scarce sometimes": 2,
          "Difficult to light (sometimes)": 2,
          "Injuries/burn from sparking/heat": 1,
          Tiring: 1,
          "Requires a lot space for storage": 1,
          "Cleaning up is a challenge": 1,
        },
      },
    ],
  },
  // ADVANTAGES OF GAS
  {
    distribution: "What are the advantages of using gas",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Cook fast (er)": 51,
          Efficient: 7,
          clean: 7,
          Available: 6,
          "Affordable/cheaper": 6,
          "Easy to move around": 5,
          "Adapted/suitable for the activity": 5,
          "Useful/Helpful": 3,
          Reliable: 3,
          "Good (substitute)": 3,
          Accessible: 3,
        },
      },
    ],
  },
  // DISADVANTAGES OF GAS
  {
    distribution: "What are the disadvantages of using gas",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Expensive: 67.6,
          "Danger of explosion/fire/burn": 19.6,
          "Finishes quickly": 5.8,
          "(frequent) shortage/scarce": 3.2,
          "Delay of delivery": 2.2,
          "Not Accessible": 1.6,
        },
      },
    ],
  },
  // ADVANTAGES OF ELECTRICITY
  {
    distribution: "What are the advantages of using electricity",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          "Speeds up work": 26,
          clean: 14,
          Available: 12,
          Accessible: 11,
          Efficient: 8,
          "Enables work": 7,
          "Affordable/cheaper": 6,
          "Lights/brightens well": 6,
          Convenient: 5,
          "Easy to use": 5,
        },
      },
    ],
  },
  // DISADVANTAGES OF ELECTRICITY
  {
    distribution: "What are the disadvantages of using electricity",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Expensive: 66,
          "(Frequent) power outage": 29,
          "Danger/risk of fire/shock": 3,
          "Current flactuates": 2,
        },
      },
    ],
  },
  // ADVANTAGES OF ELECTRICITY
  {
    distribution: "What do you use electricity for",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          Lighting: 30,
          "Charging cell phones": 22,
          "Manufacturing (fabrication)": 16,
          "Playing music": 11,
          Refrigeration: 11,
          "Cooking/heating": 8,
          "operate appliance/machine": 2,
          Milling: 1,
          "all the work": 1,
        },
      },
    ],
  },
  // NUMBER OF EMPLOYEES
  {
    distribution: "Number of Employees",
    options: {
      type: "bar",
      optionType: "vertical",
      className: "w-11 md:w-10 relative",
      id: "energy_use",
      filter: "Overall",
      percent: true,
      tableOnly: false,
      showTable: true,
      showChart: true,
      showFilter: false,
    },
    chartData: [
      {
        dataOn: "Overall",
        data: {
          2: 20.6,
          1: 18.12,
          0: 17.49,
          3: 12.11,
          5: 8.7,
          4: 8.66,
          6: 3.45,
          7: 2.65,
          10: 1.51,
          15: 1.26,
          8: 1.22,
          12: 0.76,
          9: 0.67,
          20: 0.46,
          11: 0.34,
          16: 0.25,
          13: 0.21,
          18: 0.21,
          30: 0.17,
          25: 0.13,
          17: 0.13,
          14: 0.13,
          21: 0.08,
          43: 0.08,
          35: 0.08,
          22: 0.04,
          32: 0.04,
          50: 0.04,
          33: 0.04,
          41: 0.04,
          70: 0.04,
          45: 0.04,
          65: 0.04,
          51: 0.04,
          26: 0.04,
          40: 0.0,
          38: 0.04,
          34: 0.04,
        },
      },
    ],
  },
];
