import React from 'react'
import { Translate } from 'react-auto-translate';
import {Button} from 'primereact/button'

export default function SubTitle() {
  return (
    <div className="mt-0 lg:mt-4">
      <p className="text-base md:text-lg lg:pr-5">
        <Translate>
          The JUSTIS digital portal seeks to bridge the gap between green
          entrepreneurs, policymakers, and investors to accelerate green growth
          and green transitions.
        </Translate>
      </p>

      <Button
        className="p-button-sm border-noround w-15rem flex justify-content-center mt-5"
        onClick={() => window.open("https://social.justis.africa/")}
      >
        <span>
          <Translate>Join Justis Social</Translate>
        </span>
      </Button>
    </div>
  );
}
