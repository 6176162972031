// styles
import "./styles/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primeflex/primeflex.css";
import "./styles/customs.css";
import React from "react";
import ReactGA from "react-ga";

// router
import { BrowserRouter as Router } from "react-router-dom";
import RootRouter from "./router/RootRouter";
import ScrollToTop from "./hooks/ScrollToTop";

import { Translator } from "react-auto-translate";
import { useSettingsStore } from "./zustand/useSettings";

// This is just an example of how you could wire this to localStorage
const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem("translations")) || {
      [key]: {},
    };
    existing[key] = { ...existing[key], [language]: value };
    localStorage.setItem("translations", JSON.stringify(existing));
  },
};

function App() {
  PrimeReact.ripple = true;
  // Initialize React Ga with your tracking ID
  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
  const lang = useSettingsStore((store) => store.lang);

  return (
    <Translator
      cacheProvider={cacheProvider}
      to={lang}
      from="en"
      googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATION_KEY}
    >
      <Router>
        <ScrollToTop />
        <RootRouter />
      </Router>
    </Translator>
  );
}

export default App;
