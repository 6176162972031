import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useSettingsStore } from "../../zustand/useSettings";

export default function LanguageSelect({ textColor }) {
  const menu = useRef();
  const { lang, setLang } = useSettingsStore();

  const languages = [
    {
      value: "en",
      text: "EN",
      flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/510px-Flag_of_the_United_Kingdom_%281-2%29.svg.png",
    },
    {
      value: "fr",
      text: "FR",
      flag: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/500px-Flag_of_France.svg.png",
    },
  ];

  let items = [
    {
      label: "Change Language",
      items: [
        {
          label: "English",
          command: () => setLang("en"),
        },
        {
          label: "Français",
          command: () => setLang("fr"),
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Button
        className={`mr-1 md:mr-2 ${
          textColor ? "text-white" : "text-color"
        } cursor-pointer p-0 p-button-text`}
        onClick={(e) => menu.current.toggle(e)}
        onMouseEnter={(e) => menu.current.toggle(e)}
      >
        <span className="text-sm md:text-base white-space-nowrap overflow-hidden text-overflow-clip">
          {languages.filter((item) => item.value === lang)[0].text}
          <img
            alt=""
            src={languages.filter((item) => item.value === lang)[0].flag}
            style={{
              objectFit: "cover",
              height: 10,
              width: 15,
              marginLeft: 5,
            }}
          />
        </span>
      </Button>

      <Menu
        model={items}
        popup
        ref={menu}
        className="border-noround text-sm py-2 mt-1"
        onMouseLeave={(e) => menu.current.toggle(e)}
      />
    </React.Fragment>
  );
}
