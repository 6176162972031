import React from "react";
import { Translate } from "react-auto-translate";
import Wrapper from "../containers/Wrapper";

export default function JustisProject() {
  

  return (
    <section className="flex flex-row flex-wrap gap-5 align-items-center mt-2 md:mt-5 lg:mt-7">
      <div className="w-12 lg:w-6 about-justis h-20rem md:h-25rem lg:h-30rem"></div>
      <div className="w-12 lg:w-6 flex flex-column gap-5 col lg:p-4 ">
        <strong className="text-2xl md:text-4xl lg:text-5xl">
          <Translate>The Justis Project</Translate>
        </strong>
        <div className="text-base line-height-3">
          <Translate>
            The global drive towards net-zero requires rapid transitions to
            clean energy systems.Without specific efforts to ensure an equitable
            transition, existing injustices will be exacerbated, resulting in
            winners and losers. This is particularly so for the informal sector,
            one of the largest productive sectors in Africa, accounting for 80%
            of urban jobs on the continent.
          </Translate>
        </div>

        <div className="text-base line-height-3">
          <Translate>
            Given the diverse socio-economic repercussions and outcomes of a
            transition for various stakeholders, it is imperative to investigate
            how the informal economy will respond to the transition and
            potential strategies for enhancing resilience. This study aims to
            provide a clearer understanding of effective strategies for
            addressing energy shortages, stimulating energy entrepreneurship,
            and bolstering adaptation and resilience in the informal sector.
          </Translate>
        </div>
      </div>
    </section>
  );
}
