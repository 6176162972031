import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

var userLang = navigator.language || navigator.userLanguage;

let store = (set) => ({
  lang: userLang.slice(0, 2),
  setLang: (value) => set((state) => ({ lang: value })),
});

store = devtools(store);
store = persist(store, { name: "_lang_" });

export const useSettingsStore = create(store);
