import React from "react";
import { Image } from "primereact/image";
import placeholder from "../../assets/news/placeholder.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Translate } from "react-auto-translate";
import Paginator from "../containers/Paginator";

export default function News({ data }) {
  const uniqueData = [
    ...new Map(data.map((item) => [item["title"], item])).values(),
  ];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(6);

  const top = React.useRef(null);

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return uniqueData.slice(startIndex, endIndex);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(uniqueData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="w-full">
      <div ref={top} />
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 800: 2, 1300: 3 }}>
        <Masonry gutter={"30px"} className="">
          {getCurrentItems().map((item, index) => {
            return (
              <a
                href={item.url}
                key={index}
                target="_blank"
                rel="noreferrer"
                className="text-color no-underline"
                style={{
                  width: "100%",
                  display: "block",
                }}
              >
                <NewsItem key={index} item={item} />
              </a>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>

      <Paginator
        pageNumbers={pageNumbers}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        scrollTo={scrollTo}
        top={top}
        onBack={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollTo(top);
          }
        }}
        onNext={() => {
          if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
            scrollTo(top);
          }
        }}
      />
    </section>
  );
}

// News items
const NewsItem = ({ item }) => {
  return (
    <div className="w-full">
      <Image
        src={
          item?.image.includes("favicon")
            ? placeholder
            : item?.image ?? placeholder
        }
        alt={item?.id}
        imageClassName="h-20rem md:h-25rem"
        imageStyle={{
          objectFit: "cover",
          width: "100%",
          display: "block",
        }}
      />
      <div className="w-full">
        <p className="flex gap-2 md:gap-3 align-items-center mt-3">
          <small className="font-light">
            <Translate>From</Translate>{" "}
            <Translate>{item?.source.name ?? "Anonymous"}</Translate>
          </small>
        </p>
        <p className="text-base md:text-lg lg:text-xl mt-3 mb-0 font-semibold">
          <Translate>{item?.title}</Translate>
        </p>
      </div>
    </div>
  );
};
